import axios from "axios";
import { BaseUrl } from "./constant";
import { store } from "../redux/store";
import { resetUser } from "../redux/reducers/userSlie";
import { notify } from "./notify";
export const HTTP_CLIENT = axios.create({
  baseURL: BaseUrl,
});
const setupAxios = () => {
  HTTP_CLIENT.interceptors.request.use(
    (config) => {
      const {
        user: { user },
      } = store.getState();

      if (user && user?.token) {
        config.headers.Authorization = `Bearer ${user?.token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
  HTTP_CLIENT.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      console.log(err.response, "errrr");
      if (err.response.status == 403) {
        notify(err?.response?.data?.message, "error");
        store.dispatch(resetUser());
      }
      return Promise.reject(err);
    }
  );
};
export const initialConfig = () => {
  setupAxios();
};

initialConfig();

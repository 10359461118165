import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../assets/images/avatar.png";
import aviPlaceholder from "../../../assets/images/aviPlaceholder.svg"
import { UPdateUser, UPdateAdmin } from "../../services/user.service";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { notify } from "../../utils/notify";
import { fabClasses } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const genders = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Fe-Male",
  },
  {
    value: "other",
    label: "Other",
  },
];
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function UserUpdate(props) {
  const changeDateFormat = (age) => {
    const myArray = age?.split("/");
    if (myArray) {
      var newAge = myArray[2] + "-" + myArray[1] + "-" + myArray[0];
    }
    return newAge;
  };
  const { updstate } = props;
  const data = props.id;
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(true);
  const [firstName, setfirstName] = useState(data?.firstName);
  const [lastName, setlastName] = useState(data?.lastName);
  const [gender, setgender] = useState(data?.gender);
  const [Age, setAge] = useState(changeDateFormat(data?.age));
  const [zipCodes, setzipCodes] = useState(data?.zipCode);
  const [btnLoader, setBtnLoader] = useState(false);
  const [startDate, setStartDate] = useState(data?.age ? new Date(moment(data?.age, 'DD/MM/YYYY').format('MM/DD/yyyy')) : "");



  const [password, setpassword] = useState();
  const [phoneNumber, setphoneNumber] = useState(
    data?.phoneNumber ? String(data?.phoneNumber) : ""
  );
  const [profillle, setprofillle] = useState(
    data.profilePic ? data.profilePic : ""
  );
  const [profileUrl, setProfileUrl] = useState(data?.profilePic);
  const [updata, setUpdata] = useState(false);
  const [loader, setLoader] = useState(false);
  const [idddd, setidddd] = useState(data._id);

  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const uploadFiles = (e) => {
    let file = e.target.files[0];
    if (file.type.includes("image")) {
      if (file) {
        let url = URL.createObjectURL(file);
        setProfileUrl(url);
        setprofillle(file);
      }
    } else {
      notify("Please select just image type", "error");
    }
  };
  const handleAge = (age) => {
    var date = new Date(age);
    var formatedDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return formatedDate;
  };

  const handleSubmit = () => {
    var newDate = handleAge(Age);
    let obj = {
      firstName: firstName,
      lastName: lastName,
      profilePic: profillle,
      age: moment(startDate).format('DD/MM/YYYY'),
      gender: gender,
      zipCode: zipCodes,
    };
    if (password) {
      obj["password"] = password;
    }

    setBtnLoader(true);
    UPdateUser(data?._id, obj)
      .then(({ data }) => {
        if (data.status) {
          props.setOpen(false)
          setBtnLoader(false);
          // updstate("Update Successfully");
          handleClose();
          props.update(idddd, obj, profileUrl);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleSubmit2 = () => {
    var newDate = handleAge(Age);
    let obj = {
      firstName: firstName,
      lastName: lastName,
    };

    UPdateAdmin(data?._id, obj)
      .then(({ data }) => {
        if (data.status) {
          updstate("Update Successfully");
          handleClose();
          props.update(idddd, obj, profileUrl);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };



  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="dialoginMUI"
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div className="maindivuser">
          <h2 style={{ paddingLeft: "10px" }}>{props?.title} Data</h2>
          <hr style={{ width: "97%" }}></hr>
          <div className="useruPDATE">
            {props?.title == "Admin" ? (
              <>
                <div className="listingupdate">
                  <TextField
                    label="First Name"
                    id="margin-none"
                    value={firstName}
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      if (data.firstName != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div>
                <div className="listingupdate">
                  <TextField
                    label="Last Name"
                    id="margin-none"
                    value={lastName}
                    onChange={(e) => {
                      setlastName(e.target.value);
                      if (data.lastName != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="listingupdate">
                  <TextField
                    label="First Name"
                    id="margin-none"
                    value={firstName}
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      if (data.firstName != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div>
                <div className="listingupdate">
                  <TextField
                    label="Last Name"
                    id="margin-none"
                    value={lastName}
                    onChange={(e) => {
                      setlastName(e.target.value);
                      if (data.lastName != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div>
                <div className="listingupdate">
                  <TextField
                    label="Password"
                    id="margin-none"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                      if (data.password != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div>
                <div className="listingupdate">
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Gender"
                    value={gender ? gender : ""}
                    onChange={(e) => {
                      setgender(e.target.value);
                      if (data.gender != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  // variant="standard"
                  >
                    {genders.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="listingupdate">
                  <TextField
                    label="Zip Code"
                    id="margin-none"
                    type="number"
                    value={zipCodes}
                    onChange={(e) => {
                      if (e.target.value.length <= 5) {
                        setzipCodes(e.target.value);
                      }

                      if (data.zipCodee != e.target.value) {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div>
                {/* <div className="listingupdate">
                  <TextField
                    type="date"
                    label=""
                    id="margin-none"
                    inputProps={{ min: 0 }}
                    value={Age}
                    onChange={(e) => {
                      handleAge(e.target.value);
                      setAge(e.target.value);
                      if (e.target.value != "") {
                        setUpdata(true);
                      } else {
                        setUpdata(false);
                      }
                    }}
                  />
                </div> */}
                <div className="listingupdate" style={{ position: "relative" }}>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setUpdata(true)
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    className="datepickInput"

                  />
                  {/* <div style={{ position: "absolute", top: "25px", right: "25px" }}>
                    <CalendarTodayIcon style={{ color: "grey" }} />
                  </div> */}
                </div>
                <div className="listingupdate">
                  <label>Profile pic</label>
                  <br />
                  <div className="imgflex">
                    <label htmlFor="upload_image">
                      <img style={{ height: "120px", width: "120px" }} src={profileUrl ? profileUrl : aviPlaceholder} />
                    </label>
                    <input
                      type="file"
                      id="upload_image"
                      name="upload_image"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => {
                        uploadFiles(e);
                        setUpdata(true);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {updata && props.title == "Admin" ? (
            <button
              onClick={() => {
                handleSubmit2();
                setLoader(true);
              }}
              className="bttn btn btnupdate"
              style={{ marginLeft: "10px" }}

            >
              Update
            </button>
          ) : updata && props.title != "Admin" ? (
            <div className="updateBtnDiv">
              <button
                onClick={() => {
                  handleSubmit();
                  setLoader(true);
                }}
                className="addAdminBtn addNew"
                style={{ marginLeft: "10px" }}
              >
                {btnLoader ?
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress className="spinnerLoader" color="inherit" />
                  </Box> :
                  "Update"}
              </button>
            </div>
          ) : (
            ""
          )}
          {loader ? <CircularProgress className="circulerr" /> : " "}
        </div>
      </Dialog>
    </div>
  );
}
export default UserUpdate;

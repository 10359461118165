import { HTTP_CLIENT } from "../utils/interceptor";
import {
  Endpoint_Userapi,
  Endpoint_AllAdmin,
  FavouriteBeaches,
  EditProfile,
} from "../utils/endpoint";
const UserapiData = (query) => {
  if (query) {
    return HTTP_CLIENT.get(Endpoint_Userapi.userApi + query);
  } else {
    return HTTP_CLIENT.get(Endpoint_Userapi.userApi);
  }
};

const favouriteBeachesData = (query) => {
  if (query) {
    return HTTP_CLIENT.get(FavouriteBeaches.getFavBeaches + query);
  } else {
    return HTTP_CLIENT.get(FavouriteBeaches.getFavBeaches);
  }
};

const UserAPIwithFilter = (active, perPage, startDate, endDate) => {
  return HTTP_CLIENT.get(
    Endpoint_Userapi.userApi +
      `?pageNo=${active}&perPage=${perPage}&startDate=${startDate}&endDate=${endDate}`
  );
};

const AllAdmin = (query) => {
  if (query) {
    return HTTP_CLIENT.get(Endpoint_AllAdmin.AllAdmin + query);
  } else {
    return HTTP_CLIENT.get(Endpoint_AllAdmin.AllAdmin);
  }
};

const changePasswordService = (obj) => {
  return HTTP_CLIENT.put(EditProfile?.changePassword, obj);
};

const changeUserInfoService = (obj) => {
  return HTTP_CLIENT.put(EditProfile?.editProfileInfo, obj);
};

export {
  UserapiData,
  AllAdmin,
  UserAPIwithFilter,
  favouriteBeachesData,
  changePasswordService,
  changeUserInfoService
};

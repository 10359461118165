import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../assets/images/avatar.png";
import AviPlaceholder from "../../../assets/images/aviPlaceholder.svg"
import moment from "moment";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import ProgressiveImage from "react-progressive-graceful-image";



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const handleAge = (age) => {
  if (age && age != "") {
    const myArray = age.split("/");
    var newAge = myArray[1] + "/" + myArray[0] + "/" + myArray[2];
    var nDate = new Date(newAge);
    var today = new Date();
    var distance = today.getTime() - nDate.getTime();
    var daysOld = Math.floor(distance / (1000 * 60 * 60 * 24));
    var yearsOld = Number((daysOld / 365).toFixed(0));
    return yearsOld;
  }
  return "";
};
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function UserView(props) {
  const data = props.resp;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        {props.feedss ? (
          <div class="scrollbar scrollbar-lady-lips">
            <div class="force-overflow">
              <div className="lelel">
                <h2>Complain Detail</h2>
                <div className="flexxview reportsfeed">
                  <div className="detaill">
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">Report To</h6>
                      <p className="popUpDetail">{data?.reportedUserId?.firstName}</p>
                    </div>
                    <hr />

                    <div className="reportview">
                      <h6 className="popUpHeading">Report By</h6>
                      <p className="popUpDetail"> {data?.userId?.firstName}</p>
                    </div>
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">{data?.productId ? "Product" : "Beach Title"}</h6>
                      <p className="popUpDetail">{data?.productId ? data?.productId?.title : data?.beachId?.name}</p>
                    </div>
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">Reason</h6>
                      <p className="popUpDetail jusstify">{data?.reason}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) :
          props.title === "Admins" ?
            (
              <div class="scrollbar scrollbar-lady-lips">
                <div class="force-overflow">
                  <div className="lelel">
                    <h2 className="headingInModal">Admin Detail</h2>
                    <div className="flexxview">
                      <div className="profile">
                        {/* <img src={data.profilePic ? data.profilePic : Avatar} /> */}
                        {/* <ProgressiveImage src={data.profilePic ? data.profilePic : Avatar } placeholder={Loaadings}>
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading" : " loaded"}`}
                        src={src}
                        alt={Avatar}
                        width={"100%"}
                        style={{ borderRadius: "2px" }}
                      />
                    )}
                  </ProgressiveImage> */}
                      </div>
                      <div className="detaill">
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">First Name</h6>
                          <p className="popUpDetail">{data.firstName}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Last Name</h6>
                          <p className="popUpDetail">{data.lastName}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Email</h6>
                          <p className="popUpDetail">{data.email}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Block </h6>
                          <p className="popUpDetail">{data.isBlock ? "Yes" : "No"} </p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Delete</h6>
                          <p className="popUpDetail">{data.isDelete ? "Yes" : "No"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) :
            (
              <div class="scrollbar scrollbar-lady-lips">
                <div class="force-overflow">
                  <div className="lelel">
                    <h2>User Detail</h2>
                    <div className="flexxview">
                      <div className="profile">
                        {/* <img src={data.profilePic ? data.profilePic : Avatar} /> */}
                        <ProgressiveImage src={data.profilePic ? data.profilePic : AviPlaceholder} placeholder={Loaadings}>
                          {(src, loading) => (
                            <img
                              className={`image${loading ? " loading" : " loaded"}`}
                              src={src}
                              alt={Avatar}
                              width={"100%"}
                              style={{ borderRadius: "2px", width: "140px", height: "140px", marginBottom: "50px" }}
                            />
                          )}
                        </ProgressiveImage>
                      </div>
                      <div className="detaill">
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">First Name</h6>
                          <p className="popUpDetail">{data.firstName}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Last Name</h6>
                          <p className="popUpDetail">{data.lastName}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Email</h6>
                          <p className="popUpDetail">{data.email}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Gender</h6>
                          <p className="popUpDetail">     {data?.gender == "undefined" ? "--" : data?.gender}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Zip Code</h6>
                          <p className="popUpDetail">
                            {" "}
                            {data?.zipCode == "undefined" ? "--" : data?.zipCode}
                          </p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">DOB</h6>
                          <p className="popUpDetail">{data.age}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Age</h6>
                          <p className="popUpDetail">{handleAge(data.age)}</p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Type</h6>
                          <p className="popUpDetail">{data?.isSocial ? "Social User" : "User"}</p>
                        </div>

                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">User Blocked </h6>
                          <p className="popUpDetail">{data.isBlock ? "Yes" : "No"} </p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">User Delete</h6>
                          <p className="popUpDetail">{data.isDelete ? "Yes" : "No"}</p>
                        </div>
                        {/* <hr />
                  <div className="reportview">
                    <h6 className="popUpHeading">Email Verified</h6>
                    <p className="popUpDetail">{data.isEmailVerified ? "Yes" : "No"}</p>
                  </div> */}

                        {/* <hr />
                  <div className="reportview">
                    <h6 className="popUpHeading">Device Id</h6>
                    <div className="devideiid">
                      <p className="popUpDetail">{data.deviceId}</p>
                    </div>
                  </div> */}
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Active Hours</h6>
                          <p className="popUpDetail">
                            <span className="active-hours">Start: </span>
                            {moment(data.activeHours.start).format("LT")} <br />
                            <span className="active-hours">End: </span>
                            {moment(data.activeHours.end).format("LT")}
                          </p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Daily Report Time</h6>
                          <p className="popUpDetail">
                            <span className="active-hours">Time: </span>
                            {moment(data.emailHours.time).format("LT")} <br />
                            <span className="active-hours">Alert: </span>
                            {data.emailHours.alert ? "Yes" : "No"}
                          </p>
                        </div>
                        <hr />
                        <div className="reportview">
                          <h6 className="popUpHeading">Active days</h6>
                          <div className="flexxiidays">
                            {data.activeDays.map((i) => {
                              return (
                                <>
                                  <div className="bioflex">
                                    <h6 className="inerflexi popUpDetail">{i.name}</h6>
                                  </div>
                                  <div className="bioflex">
                                    <span className="inerflexi popUpDetail">
                                      {i.alert ? "Yes" : "No"}
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
      </Dialog>
    </div>
  );
}
export default UserView;

import { HTTP_CLIENT } from "../utils/interceptor";
import { Endpoint_userApi_UnBlock } from "../utils/endpoint";
const Userapi_unblockCoversationData = (obj) => {
  // if (id){
  return HTTP_CLIENT.put(
    Endpoint_userApi_UnBlock.userApi_Conversation_unBlock,
    obj
  );
  // }
};
const Userapi_unblockData = (obj) => {
  // if (id){
  return HTTP_CLIENT.put(Endpoint_userApi_UnBlock.userApi_unBlock + obj, obj);
  // }
};
export { Userapi_unblockData, Userapi_unblockCoversationData };

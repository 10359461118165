import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../../assets/css/App.css";
import arrowBold from "../../../assets/images/arrowBold.png";
import Loadering from "../../../assets/images/loading.gif";
import wave from "../../../assets/images/wave.png";
import { get_all_beaches } from "../../services/Veiwers";
import Graph from "./graph";

// For AutoComplete
import { getWaveForm } from "../../utils/helper";
import { weatherDataService } from "../../services/weatherApi";
import { notify } from "../../utils/notify";

function Weather() {
  const [open, setOpen] = useState(true);
  const [tides, setTides] = useState([]);
  const [tempWeather, setTempWeather] = useState([]);
  const [beeches, setBeeches] = useState([]);
  const [weatherForcast, setWeatherForcast] = useState([]);
  const [selectedBeach, setSelectedBeach] = useState(null);
  useEffect(() => {
    if (selectedBeach) {
      getdata();
    }
  }, [selectedBeach]);
  useEffect(() => {
    get_all_beaches()
      .then(({ data }) => {
        let unique = [];
        data.data.map((i) => {
          if (!unique.includes(i.name)) {
            unique.push(i.name);
          }
        });
        setBeeches(data.data);
        setSelectedBeach(data.data[0]);
      })
      .catch((err) => {});
  }, []);
  const getdata = async () => {
    setOpen(true);
    weatherDataService(selectedBeach?._id)
      .then(({ data: { data } }) => {
        setTempWeather(data);
        setOpen(false);
      })
      .catch((err) => {
        notify("Something went wrong");
        setOpen(false);
      });
  };

  useEffect(() => {
    settidesData(tempWeather);
    manipulateData(tempWeather);
    getWaterTemperature();
  }, [tempWeather]);

  const settidesData = (data) => {
    let temp = [];

    let tempDate = moment().add(0, "day").format("YYYY-MM-DD");
    let tempDate1 = moment().add(1, "day").format("YYYY-MM-DD");
    let tempDate2 = moment().add(2, "day").format("YYYY-MM-DD");
    let tempDate3 = moment().add(3, "day").format("YYYY-MM-DD");
    let tempDate4 = moment().add(4, "day").format("YYYY-MM-DD");
    data?.map((item) => {
      if (
        item?.date === tempDate ||
        item?.date === tempDate1 ||
        item?.date === tempDate2 ||
        item?.date === tempDate3 ||
        item?.date === tempDate4
      ) {
        temp.push({ tides: item?.tides[0], date: item?.date });
      }
    });
    setTides(temp);
  };
  const day = (date) => {
    let days = "";
    let newDate = `'${date}'`;
    let dates = new Date(newDate).getDay();
    if (dates == 1) {
      days = "MON";
    } else if (dates == 2) {
      days = "TUE";
    } else if (dates == 3) {
      days = "WED";
    } else if (dates == 4) {
      days = "THR";
    } else if (dates == 5) {
      days = "FRI";
    } else if (dates == 6) {
      days = "SAT";
    } else if (dates == 7) {
      days = "SUN";
    } else {
      days = "";
    }
    return days;
  };
  const selecttt = (item) => {
    setSelectedBeach(item);
  };
  const manipulateData = (data) => {
    const TIME_DATA = [
      {
        time: "5AM",
        index: 500,
      },
      {
        time: "11AM",
        index: 1100,
      },
      {
        time: "5PM",
        index: 1700,
      },
    ];
    let temp = [];

    data?.map((item) => {
      let day = [];
      let waveForm = "";
      let swellData = "";
      let desc = "";
      let swellPeriod_secs = "";
      let swellDirection = "";
      let windData = [];
      let waveData = [];

      TIME_DATA?.map((time_item) => {
        let hourData = item?.hourly?.find((hourly_elem) => {
          return hourly_elem?.time == time_item?.index;
        });

        const waveHeight = Number((hourData?.sigHeight_m * 3.28084).toFixed(2));
        waveForm = getWaveForm(
          waveHeight,
          hourData?.windspeedKmph,
          hourData?.swellPeriod_secs
        );
        swellData = hourData?.swellDir16Point;
        desc = hourData?.weatherDesc[0].value;
        swellPeriod_secs = hourData?.swellPeriod_secs;
        swellDirection = hourData?.swellDir;

        windData.push({
          time: time_item?.time,
          speed: hourData?.windspeedMiles,
          angle: hourData?.winddirDegree,
        });
        waveData.push({
          time: time_item?.time,
          waveForm: waveForm,
          swellDirection: swellDirection,
          swellPeriod_secs: swellPeriod_secs,
          swellHeight_ft: waveHeight,
        });
        day.push(Number(hourData?.swellHeight_ft));
      });
      temp.push({
        date: item?.date,
        dayData: day,
        waveForm: waveForm,
        swellData: swellData,
        desc: desc,
        windData: windData,
        swellPeriod_secs,
        swellDirection,
        waveData: waveData,
      });
      setWeatherForcast(temp);
    });
  };

  const getWaterTemperature = (item) => {
    let temp = [];
    item?.hourly?.map((val) => {
      temp?.push(val?.waterTemp_F);
      temp.sort();
    });
    let temps = [];
    temps.push(temp[0]);
    temps.push(temp[temp.length - 1]);
    return [`${temps[0]} - ${temps[1]}`];
  };

  const handleClear = () => {
    setSelectedBeach(beeches[0]);
  };

  const handleOnHover = (result) => {};

  const handleOnSelect = (item) => {
    // Function used for selecting autocomplete item before
    selecttt(item);
  };

  const handleOnFocus = (item) => {};

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const getMinMax = (objArr, str) => {
    let arr = [];

    objArr.forEach((i) => {
      arr.push(i.waterTemp_F);
    });

    if (str == "max") {
      if (arr.length) {
        return Math.max(...arr);
      }
    } else if (str == "min") {
      return Math.min(...arr);
    }
  };

  return (
    <div className="main_bar">
      <div className="heading">
        <h3>Weather</h3>
      </div>
      <div className="seleeect">
        <div className="App">
          <header className="App-header">
            <div style={{ width: 400 }} className="searchBeaches">
              <ReactSearchAutocomplete
                items={beeches}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleClear}
                autoFocus
                formatResult={formatResult}
                placeholder={beeches[0]?.name}
              />
            </div>
          </header>
        </div>
      </div>
      <hr></hr>
      {/* -----Regional Forcast--------- */}

      <div className="WeatherOne">
        <h2 className="weatherHeading">Regional Forcast</h2>
        {open ? (
          <div className="center">
            <img src={Loadering} className="loaderr " />{" "}
          </div>
        ) : (
          <div className="scrollmenu">
            {weatherForcast?.map((item, index) => {
              return (
                <>
                  <div class="divData">
                    <div className="">
                      <p style={{ textAlign: "left" }}>
                        {day(item?.date)} - {item?.date}
                      </p>
                      {/* <p className="statuss">{item?.waveForm}</p> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {item?.waveData?.map((itm, inx) => {
                        return (
                          <div className="windData">
                            <p className="dateTime">{itm?.time}</p>
                            <p className="">{itm?.waveForm}</p>
                            <p className="heights">{itm?.swellHeight_ft} ft</p>
                            <p className="interval">
                              <span>
                                <img
                                  style={{
                                    transform: `rotate(${itm?.swellDirection}deg)`,
                                    height: "42px",
                                    width: "42px",
                                  }}
                                  src={arrowBold}
                                />
                              </span>
                              {Math.round(itm?.swellPeriod_secs)}sec
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
      {/* -----Tide--------- */}

      <div className="WeatherOne">
        <h2 className="weatherHeading">Tide</h2>
        {open ? (
          <div className="center">
            <img src={Loadering} className="loaderr " />{" "}
          </div>
        ) : (
          <Graph tides={tides} />
        )}
      </div>
      {/* -----Wind--------- */}

      <div className="WeatherOne">
        <h2 className="weatherHeading">Wind</h2>
      </div>

      {open ? (
        <div className="center">
          <img src={Loadering} className="loaderr " />{" "}
        </div>
      ) : (
        <div class="scrollmenu">
          {weatherForcast.map((item) => {
            return (
              <div className="divData">
                <p style={{ textAlign: "left" }}>
                  {moment(item?.date).format("dddd d/M/YYYY")}
                </p>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  {item.windData.map((items) => {
                    return (
                      <div className="windData">
                        <p style={{ padding: "0x", marginBottom: "10px" }}>
                          {" "}
                          {items?.time}{" "}
                        </p>
                        <p
                          style={{
                            padding: "0x",
                            margin: "0px",
                            fontSize: "18px",
                            color: "#a09393",
                          }}
                        >
                          {" "}
                          {items?.speed}mph
                        </p>
                        <img
                          style={{
                            transform: `rotate(${items?.angle}deg)`,
                            height: "30px",
                            width: "30px",
                            marginTop: "10px",
                          }}
                          src={arrowBold}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* -----Weather--------- */}

      <div className="WeatherOne">
        <h2 className="weatherHeading">Weather</h2>
      </div>
      {open ? (
        <div className="center">
          <img src={Loadering} className="loaderr " />{" "}
        </div>
      ) : (
        <div class="scrollmenu">
          {tempWeather?.map((item) => {
            return (
              <>
                <div className="WeatherData weatherScroll">
                  <p style={{ textAlign: "left" }}>
                    {moment(item?.date).format("dddd D/M/YYYY")}
                  </p>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <img
                      src={item?.hourly[0]?.weatherIconUrl[0].value}
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div>
                      <p className="weaText">Weather Temperature</p>
                      <p className="scale">
                        {item.mintempF} - {item.maxtempF} °F
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <img src={wave} className="imgWeather" />
                    <div>
                      <p className="weaText">Water Temperature</p>
                      <p className="scale">
                        {getMinMax(item?.hourly, "min")} -{" "}
                        {getMinMax(item?.hourly, "max")} °F
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      <p>Sun Rise</p>
                      <p>{item.astronomy[0].sunrise}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      <p>Sun Set</p>
                      <p>{item.astronomy[0].sunset}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Weather;

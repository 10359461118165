
import {HTTP_CLIENT} from '../utils/interceptor'
import {Endpoint_get_all_conversations,Endpoint_get_messages} from '../utils/endpoint'
const Get_all_conversations = ()=>{
    return HTTP_CLIENT.get(Endpoint_get_all_conversations.get_all_conversations)
 
}
const Get_messages = (id)=>{
    return HTTP_CLIENT.get(Endpoint_get_messages.get_messages+id)
 
}
export {
    Get_all_conversations,
    Get_messages
}


import { HTTP_CLIENT } from "../utils/interceptor";
import { Endpoint_Product } from "../utils/endpoint";
const UPdateProduct = (id, obj) => {
  let formbody = new FormData();
  Object.keys(obj).forEach((key, inx) => {
    if (key == "uploadFile") {
      obj[key].forEach((keyobj, sinx) => {
        formbody.append(`uploadFile[${sinx}]`, keyobj);
      });
    } else {
      formbody.append(key, obj[key]);
    }
  });
  return HTTP_CLIENT.put(Endpoint_Product.updateProduct + id, formbody);
};

export { UPdateProduct };

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { get_all_beaches } from "../../services/Veiwers";
import moment from "moment";
import { Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { notify } from "../../utils/notify";
import { useSelector } from "react-redux";
import { singleDayWeatheService } from "../../services/weatherApi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  handleClose,
  handleCreate,
  loader,
}) {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState(null);
  const [allBeaches, setAllBeaches] = useState(null);
  const [selectedBeach, setSelectedBeach] = useState(null);
  const [perdicted, setPredicted] = useState(null);
  const [openReport, setOpenReport] = React.useState(false);

  const user = useSelector((state) => state.user.user);

  const handleClickOpen = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    onClose();
  };

  const getBeaches = () => {
    get_all_beaches()
      .then(({ data: { data } }) => {
        setAllBeaches(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBeaches();
  }, []);

  const handleCallBack = async (beach) => {
    setSelectedBeach(beach);
    if (beach) {
      singleDayWeatheService(beach?._id)
        .then(({ data: { data } }) => {
          let predicted = {
            waveSize: data.height,
            waveForm: data.form,
          };
          setPredicted(predicted);
          setOpenReport(true);
        })
        .catch((err) => {
          setPredicted(null);
        });
    } else {
      setPredicted(null);
    }
  };

  const getWaveForm = (waveHeight, windSpeed, swellInterval) => {
    const value =
      waveHeight - Number(windSpeed) / 5 + Number(swellInterval) / 7;
    return value < 2
      ? "Poor"
      : value >= 2 && value < 6
      ? "Fair"
      : value >= 6 && value < 9
      ? "Good"
      : value >= 9
      ? "Excellent"
      : "--";
  };

  const onClose = () => {
    setSelectedBeach(null);
    setLatitude(null);
    setLongitude(null);
    setPredicted(null);
    handleClose();
  };

  const handleSubmit = (description, photos, videos) => {
    let obj = {
      description: description,
      media: [
        ...photos.map((image) => image?.raw),
        ...(videos.length > 0 ? videos.map((video) => video?.raw) : []),
      ],
      waveSize: perdicted?.waveSize,
      waveForm: (perdicted?.waveForm).toLowerCase(),
      user: user?.admin?.id,
      locationTitle: selectedBeach?.name,
      locationId: selectedBeach?.place_id,
      beachId: selectedBeach?._id,
      location: JSON.stringify(selectedBeach?.location),
    };
    // handleCloseReport();
    handleCreate(obj);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ padding: "24px" }}>
          <div style={{ marginBottom: "12px", paddingBottom: "10px" }}>
            First Select beach to add report:
          </div>
          {allBeaches?.length && (
            <SelectBeach
              allBeaches={allBeaches}
              setSelectedBeach={handleCallBack}
            />
          )}

          <AddReport
            open={openReport}
            handleClickOpen={handleClickOpen}
            handleClose={handleCloseReport}
            perdicted={perdicted}
            setPredicted={setPredicted}
            handleCallback={handleSubmit}
            loader={loader}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

function SelectBeach({ allBeaches, setSelectedBeach }) {
  const handleInputChange = (event, newValue) => {
    setSelectedBeach(newValue);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        onChange={(event, newValue) => {
          handleInputChange(event, newValue);
        }}
        options={allBeaches}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Select Beach" />}
      />
    </Stack>
  );
}

function AddReport({
  open,
  handleClose,
  perdicted,
  setPredicted,
  handleCallback,
  loader,
}) {
  const [productUrls, setProductUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [description, setDescription] = useState("");

  const onClose = () => {
    setPredicted(null);
    handleClose();
    setProductUrls([]);
    setVideoUrls([]);
    setDescription("");
  };

  const deleteImg = (index) => {
    const updatedProductUrls = [...productUrls];
    updatedProductUrls.splice(index, 1);
    setProductUrls(updatedProductUrls);
  };

  const imageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const imageObject = { preview: url, raw: file };
      setProductUrls([...productUrls, imageObject]);
    }
  };

  const deleteVideo = (index) => {
    const updatedVideoUrls = [...videoUrls];
    updatedVideoUrls.splice(index, 1);
    setVideoUrls(updatedVideoUrls);
  };

  const videoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const videoObject = { preview: url, raw: file };
      setVideoUrls([...videoUrls, videoObject]);
    }
  };

  const handleSubmit = () => {
    if (description.length == 0) {
      notify(
        description.length == 0 ? "Please add description" : "Please check data"
      );
    } else {
      handleCallback(description, productUrls, videoUrls);
    }
  };

  useEffect(() => {
    if (!loader) {
      onClose();
    }
  }, [loader]);

  return (
    <div style={{ position: "relative" }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div style={{ textAlign: "center", fontSize: "20px" }}>
            Report conditions
          </div>
          {perdicted && (
            <>
              <div>
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  Predicted Wave Size
                </div>
                <div style={styles.textStyle}>{perdicted.waveSize}</div>
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  Predicted Wave Form
                </div>
                <div style={styles.textStyle}>{perdicted.waveForm}</div>
              </div>
              <div style={{ marginBottom: "12px", paddingTop: "20px" }}>
                Description
              </div>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                className="textAreaReport"
                placeholder="How are the surf conditions?"
              ></textarea>

              <div style={styles.inputContainer}>
                <div>Report Images</div>
                <div className="imgContainer">
                  {productUrls.map((img, index) => (
                    <div key={index} style={styles.imageContainer}>
                      <img
                        className="imgSelected"
                        src={img.preview}
                        alt={`Image ${index}`}
                      />
                      <ClearIcon
                        className="deleteIcon"
                        style={styles.deleteIcon}
                        onClick={() => deleteImg(index)}
                      />
                    </div>
                  ))}
                  {productUrls.length < 4 && (
                    <label
                      htmlFor="profileImage"
                      className="flex justify-center"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="plusDiv">
                        <AddIcon />
                      </div>
                    </label>
                  )}
                </div>

                <input
                  type="file"
                  id="profileImage"
                  name="profileImage"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={imageUpload}
                />
              </div>
              <div style={styles.inputContainer}>
                <div>Report Videos</div>
                <div className="videoContainer">
                  {videoUrls.map((video, index) => (
                    <div
                      key={index}
                      className="videoPlayer"
                      style={styles.videoContainer}
                    >
                      <video width="150" autoPlay muted>
                        <source src={video.preview} type="video/mp4" />
                        <source src={video.preview} type="video/ogg" />
                        Your browser does not support HTML video.
                      </video>
                      <ClearIcon
                        className="deleteIcon"
                        style={styles.deleteIcon}
                        onClick={() => deleteVideo(index)}
                      />
                    </div>
                  ))}
                  {videoUrls.length < 1 && (
                    <label
                      htmlFor="profileVideo"
                      className="flex justify-center"
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="plusDiv"
                        style={{ height: "86px", width: "150px" }}
                      >
                        <AddIcon />
                      </div>
                    </label>
                  )}
                </div>
                <input
                  type="file"
                  id="profileVideo"
                  name="profileVideo"
                  style={{ display: "none" }}
                  accept="video/*"
                  onChange={videoChange}
                />
              </div>
              <Button
                className="reportPost"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {loader ? "loading" : "Post Report"}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const styles = {
  textStyle: {
    borderBottom: "1px solid rgba(0,0,0,.1)",
    paddingBottom: "5px",
    fontWeight: "400",
  },
  inputContainer: {
    paddingTop: "11px",
    paddingBottom: "11px",
  },
  imageContainer: {
    position: "relative",
    marginRight: "4%",
  },
  deleteIcon: {
    fill: "red",
  },
  videoContainer: {
    position: "relative",
  },
};

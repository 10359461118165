import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import BackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import { React, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import Loadering from "../../../assets/images/loading.gif";
import DefaultGroupAvatar from "../../../assets/images/mountain.png";
import NoSearch from "../../../assets/images/noSearch.png";
import { addGroup, editGroup, getAllUsers } from "../../services/groupsApi";
import { notify } from "../../utils/notify";
import { useDebounce } from "../../utils/useDebounce";
import MemberCard from "./memberCard";

function GroupUpdate({
  title,
  onClose,
  data,
  edit,
  allUsers,
  setAllUsers,
  onEdit,
}) {
  const [open, setOpen] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(allUsers?.slice(0, 50));
  const [searchMenu, setsearchMenu] = useState("");
  const [addMemberLoader, setAddMemberLoader] = useState(false);
  const [addMember, setAddMember] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };
  const [groupData, setGroupData] = useState(
    edit
      ? data
      : {
          title: "",
          description: "",
          members: [],
          coverPhoto: "",
        }
  );
  useEffect(() => {
    if (addMember && !(allUsers?.length > 0)) {
      setAddMemberLoader(true);
      getAllUsers()
        .then(({ data }) => {
          setAllUsers(data?.data);
          setFilteredUsers(data?.data?.slice(0, 50));
        })
        .catch((err) => {
          notify("Error retrieving users data!");
        })
        .finally(() => {
          setAddMemberLoader(false);
        });
    }
  }, [addMember]);
  useDebounce(
    () => {
      if (searchMenu != "") {
        let data = allUsers?.filter(
          (item) =>
            item?.firstName?.toLowerCase().includes(searchMenu.toLowerCase()) ||
            item?.lastName?.toLowerCase().includes(searchMenu.toLowerCase())
        );

        setFilteredUsers(data);
      } else {
        setFilteredUsers(allUsers?.slice(0, 50));
      }
    },
    [searchMenu],
    500
  );

  const handleSubmit = () => {
    if (groupData?.title == "") {
      notify("Group Title is required.");
      return;
    }
    if (groupData?.description == "") {
      notify("Group Description is required.");
      return;
    }
    setSubmitting(true);
    if (edit) {
      let newMembers = [];
      groupData?.members?.map((item) => {
        let oldMember = data?.members?.find((elem) => elem?._id == item?._id);
        if (!oldMember) {
          newMembers.push(item?._id);
        }
      });
      let obj = {
        title: groupData?.title,
        description: groupData?.description,
        members: JSON.stringify(groupData?.members?.map((item) => item?._id)),
        newMembers: JSON.stringify(newMembers),
      };
      if (groupData.coverPhoto != data?.coverPhoto) {
        obj.picture = groupData?.file;
      }
      editGroup(groupData?._id, obj)
        .then(({ data }) => {
          if (data?.code == 200) {
            handleClose();
            let updatedData = data?.data;
            console.log("on edit", onEdit);
            onEdit &&
              onEdit({ ...groupData, coverPhoto: updatedData?.coverPhoto });
            notify("Group updated successfully", "success");
          } else {
            notify("Something went wrong!");
          }
        })
        .catch(() => {
          notify("Something went wrong!");
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      let obj = {
        title: groupData?.title,
        description: groupData?.description,
        members: JSON.stringify(groupData?.members?.map((item) => item?._id)),
      };

      if (groupData.coverPhoto != "") {
        obj.picture = groupData?.file;
      }

      addGroup(obj)
        .then(({ data }) => {
          if (data?.status == 200) {
            handleClose();
            notify("Group added successfully", "success");
          } else {
            notify("Something went wrong!");
          }
        })
        .catch(() => {
          notify("Something went wrong!");
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {addMember ? (
          <>
            <div className="add-member-header">
              <div
                className="pointer"
                onClick={() => {
                  setAddMember(false);
                }}
              >
                <BackIcon />
              </div>
              <div className="pointer" onClick={handleClose}>
                <CancelIcon />
              </div>
            </div>
            <div className="maindivuser  min-height">
              <h2 style={{ paddingLeft: "10px" }}>Users</h2>
              <hr style={{ width: "97%", marginBottom: "30px" }}></hr>
              {addMemberLoader ? (
                <div className="member-loading-div">
                  <div className="loaadds">
                    <img src={Loadering} className="loaderr" />
                  </div>
                </div>
              ) : (
                <>
                  {allUsers?.length > 0 ? (
                    <div className="padding-horizontal">
                      <div className="search_view">
                        <input
                          type="search"
                          placeholder="Search..."
                          value={searchMenu}
                          onChange={(e) => {
                            setsearchMenu(e.target.value);
                          }}
                          autoComplete="off"
                        ></input>
                      </div>
                      <>
                        {filteredUsers?.length > 0 ? (
                          <>
                            {filteredUsers?.map((item) => {
                              let alreadyMember = groupData?.members?.find(
                                (elem) => {
                                  return elem?._id == item.id;
                                }
                              );
                              return (
                                <MemberCard
                                  key={item?.id}
                                  item={item}
                                  add={!alreadyMember}
                                  remove={alreadyMember}
                                  onBtnPress={() => {
                                    if (alreadyMember) {
                                      setGroupData((pre) => {
                                        let members = pre?.members?.filter(
                                          (elem) => elem._id != item?.id
                                        );
                                        return {
                                          ...pre,
                                          members,
                                        };
                                      });
                                    } else {
                                      setGroupData((pre) => {
                                        let members = [
                                          ...pre.members,
                                          { ...item, _id: item?.id },
                                        ];
                                        return {
                                          ...pre,
                                          members,
                                        };
                                      });
                                    }
                                  }}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <div className="member-loading-div">
                            <img className="no-search" src={NoSearch} />
                            <p className="users-error-text">No Users Found.</p>
                            <p className="users-error-text">
                              Please Change Search!!
                            </p>
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    <div className="member-loading-div">
                      <p className="users-error-text">
                        Error loading users data!!
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
            <div className="maindivuser">
              <div className="edit-group-header">
                <h2 style={{ paddingLeft: "10px" }}>{title}</h2>
                <Button
                  className="add-member-btn"
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={submitting}
                >
                  {!submitting ? (
                    edit ? (
                      "Update"
                    ) : (
                      "Add Group"
                    )
                  ) : (
                    <CircularProgress
                      className="spinnerLoader"
                      color="inherit"
                    />
                  )}
                </Button>
              </div>
              <hr style={{ width: "97%", marginBottom: "30px" }}></hr>
              <div className="useruPDATE">
                <div className="group">
                  <label for="upload_image">
                    <ProgressiveImage
                      src={
                        groupData.coverPhoto
                          ? groupData.coverPhoto
                          : DefaultGroupAvatar
                      }
                      placeholder={Loaadings}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${loading ? " loading" : " loaded"}`}
                          src={src}
                          alt={Avatar}
                          width={"100%"}
                          style={{
                            borderRadius: "2px",
                            width: "140px",
                            height: "140px",
                            marginBottom: "50px",
                          }}
                        />
                      )}
                    </ProgressiveImage>
                    <input
                      type="file"
                      id="upload_image"
                      name="upload_image"
                      style={{ display: "none" }}
                      accept="image/*"
                      disabled={submitting}
                      onChange={(e) => {
                        let file = e.target.files[0];
                        if (file.type.includes("image")) {
                          if (file) {
                            let url = URL.createObjectURL(file);
                            setGroupData((pre) => {
                              return {
                                ...pre,
                                coverPhoto: url,
                                file,
                              };
                            });
                          }
                        } else {
                          notify("Please select just image type", "error");
                        }
                      }}
                    />
                  </label>
                </div>
                <div className="group-listingupdate">
                  <TextField
                    label="Title"
                    id="margin-none"
                    disabled={submitting}
                    value={groupData?.title}
                    onChange={(e) => {
                      setGroupData((pre) => {
                        return {
                          ...pre,
                          title: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="group-listingupdate">
                  <TextField
                    label="Description"
                    id="margin-none"
                    disabled={submitting}
                    value={groupData?.description}
                    multiline
                    rows={4}
                    onChange={(e) => {
                      setGroupData((pre) => {
                        return {
                          ...pre,
                          description: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
                <div className="group-listingupdate">
                  <div className="group-edit-head">
                    <p className="group-edit-heading">Group Members</p>
                    <Button
                      className="add-member-btn"
                      variant="contained"
                      onClick={() => {
                        setAddMember(true);
                      }}
                      disabled={submitting}
                    >
                      Add Member
                    </Button>
                  </div>
                  {groupData?.members?.map((item, index) => {
                    return (
                      <MemberCard
                        item={item}
                        remove={true}
                        onBtnPress={() => {
                          setGroupData((pre) => {
                            let members = pre?.members?.filter(
                              (elem) => elem._id != item?._id
                            );
                            return {
                              ...pre,
                              members,
                            };
                          });
                        }}
                        disabled={submitting}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
}
export default GroupUpdate;

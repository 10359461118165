import "../../../assets/css/App.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ApexChart from "react-apexcharts";
import { UserapiData } from "../../services/UserApi";
import { ReportapiData } from "../../services/ReportApi";
import { ProductapiData } from "../../services/ProductApi";
import Charts from './chart'


function Graph({ tides }) {
  let final1 = ""
  let final2 = ""
  let final3 = ""
  let time1 = ""
  const count = useSelector((state) => state.user.user);
  const [dataUser, setdataUser] = useState([]);
  const [dataReports, setdataReports] = useState([]);
  const [dataProducts, setdataProducts] = useState([]);
  const [tideHeight_mt1, settideHeight_mt1] = useState([]);
  const [userblock, setuserblock] = useState();
  const [userdelete, setuserdelete] = useState();
  const [reportblock, setreportblock] = useState();
  const [productblock, setproductblock] = useState();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (tides != "") {
      applay()

    }
    // Users
  }, [tides]);

  const applay = () => {
    let tides1 = tides[0]?.tides?.tide_data
    let tides2 = tides[1]?.tides?.tide_data
    let tides3 = tides[2]?.tides?.tide_data
    if (tides) {
      final1 = (tides1?.map((item) => item?.tideHeight_mt))
      setdataAapex({ ...dataApex, series: [{ data: final1 }] })

      final2 = (tides2?.map((item) => item?.tideHeight_mt))
      setdataAapex2({ ...dataApex2, series: [{ data: final2 }] })

      final3 = (tides3?.map((item) => item?.tideHeight_mt))
      setdataAapex3({ ...dataApex3, series: [{ data: final3 }] })
    }
  }



  const [dataApex, setdataAapex] = useState({
    series: [
      {
        name: "series 1",
        data: [],
      },
    ],
    options: {
      labels: [],
      dataLabels: {
        enabled: true
      },
      chart: {
        type: "area",
      },
      stroke: {
        curve: "smooth",
      },

      legend: {
        horizontalAlign: "left",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,

            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [dataApex2, setdataAapex2] = useState(
    {
      series: [
        {
          name: "series1",
          data: [],
        },
      ],


      options: {
        xaxis: {
        },
        chart: {
          type: "area",
        },
        stroke: {
          curve: "smooth",
        },

        legend: {
          horizontalAlign: "left",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });
  const [dataApex3, setdataAapex3] = useState({
    series: [
      {
        name: "series1",
        data: [],
      },
    ],

    options: {
      chart: {
        type: "area",
      },
      stroke: {
        curve: "smooth",
      },

      legend: {
        horizontalAlign: "left",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <div className="main_bar apexis">
      <div className="card_div">
        <div className="main1 flexicard">
          {/* <div id="charts">
            <ApexChart
              options={dataApex.options}
              type={"area"}
              series={dataApex.series}
            />
          </div>
          <div id="charts">
            <ApexChart
              options={dataApex.options}
              type={"area"}
              series={dataApex2.series}
            />
          </div>
          <div id="charts">
            <ApexChart
              options={dataApex.options}
              type={"area"}
              series={dataApex3.series}
            />
          </div> */}
          {
            tides?.map((i, inx) => {
              return (
                <>
                  <div id="charts" >
                    <Charts
                      tides={tides[inx]?.tides?.tide_data}
                    />
                  </div>
                </>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default Graph;

import { createSlice } from "@reduxjs/toolkit";

const initState = { userInfo: null };

export const rememberMe = createSlice({
  name: "userInfo",
  initialState: initState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    resetUserInfo: () => initState,
  },
});

export const { setUserInfo, resetUserInfo } = rememberMe.actions;

export default rememberMe.reducer;

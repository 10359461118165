import { HTTP_CLIENT } from "../utils/interceptor";
import { Endpoint_Reportapi_block } from "../utils/endpoint";
const Reportapi_blockData = (id) => {
  let obj = {
    reportIds: [id],
  };
  if (id) {
    return HTTP_CLIENT.put(Endpoint_Reportapi_block.Report_blockApi, obj);
  }
};
export { Reportapi_blockData };

import "../../../assets/css/App.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Apexxcahrt from "react-apexcharts";
import { UserapiData } from "../../services/UserApi";
import { ReportapiData } from "../../services/ReportApi";
import { ProductapiData } from "../../services/ProductApi";
import BoxIcon from "../../../assets/images/BoxIcon.svg";
import UserIcon from "../../../assets/images/UserIcon.svg";
import GraphIcon from "../../../assets/images/GraphIcon.svg";
import { getDashboardCounts } from "../../services/weatherApi";

function Dash() {
  const count = useSelector((state) => state.user.user);
  const [counts, setCounts] = useState(null);
  const [dataUser, setdaaReports] = useState([]);
  const [dataReports, setdataReports] = useState([]);
  const [dataProducts, setdataProducts] = useState([]);
  const [userblock, setuserblock] = useState();
  const [userdelete, setuserdelete] = useState();
  const [reportblock, setreportblock] = useState();
  const [productblock, setproductblock] = useState();

  useEffect(() => {
    getDashboardCounts().then(({ data }) => {
      if (data?.code == 200) {
        setCounts(data?.data);
      }
    });
    // Users
    // UserapiData()
    //   .then(({ data }) => {
    //     if (data) {
    //       setuserblock(data.data.users.filter((block) => block.isBlock).length);
    //       setuserdelete(
    //         data.data.users.filter((block) => block.isDelete).length
    //       );
    //       setdataUser(data.data.users);
    //     }
    //   })
    //   .catch(() => { });
    // // Reports
    // ReportapiData()
    //   .then(({ data }) => {
    //     if (data) {
    //       setreportblock(
    //         data.data.reports.filter((block) => block.isBlocked).length
    //       );
    //       setdataReports(data.data.reports);
    //     }
    //   })
    //   .catch(() => { });
    // // Products
    // ProductapiData()
    //   .then(({ data }) => {
    //     if (data) {
    //       setproductblock(
    //         data.data.products.filter((block) => block.isApproved).length
    //       );
    //       setdataProducts(data.data.products);
    //     }
    //   })
    //   .catch(() => { });
  }, []);
  const [dataApex, setdataAapex] = useState({
    series: [],

    options: {
      labels: ["User", "Block"],

      colors: ["#3282B8", "#64B8F0"],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [dataApex1, setdataAapex1] = useState({
    series: [],
    options: {
      labels: ["Report", "Block"],
      colors: ["#3282B8", "#64B8F0"],

      chart: {
        type: "donut",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [dataApex2, setdataAapex2] = useState({
    series: [],
    options: {
      labels: ["Products"],
      colors: ["#3282B8"],

      chart: {
        type: "donut",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  console.log("hello", count);
  return (
    <div className="main_bar">
      <div className="heading">
        <h3>DASHBOARD</h3>
        <p className="welcomePara">
          Welcome back, &nbsp;{count?.admin?.firstName}.
        </p>
      </div>
      <hr className="borderHr"></hr>
      <div className="card_div">
        <div className="main1">
          <div class="cardds one">
            <div className="title">
              <h4>Users</h4>
              {/* <i class="far fa-user"></i> */}
              <div className="imgIcon">
                <img src={UserIcon} />
              </div>
            </div>
            <hr />
            <div className="content">
              <p>
                <label>Active Users : &nbsp; </label>
                <span>{counts?.users?.registered}</span>
              </p>
              <p>
                <label>Blocked Users : &nbsp; </label>
                <span>{counts?.users?.blocked}</span>
              </p>
            </div>
          </div>
          {!!counts ? (
            <div id="chart">
              <Apexxcahrt
                options={dataApex?.options}
                series={[counts?.users?.registered, counts?.users?.blocked]}
                type="donut"
                height={350}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="main1">
          <div class="cardds two">
            <div className="title">
              <h4>Reports</h4>
              {/* <i class="far fa-chart-bar"></i> */}
              <div className="imgIcon">
                <img src={GraphIcon} />
              </div>
            </div>
            <hr />
            <div className="content">
              <p>
                <label>Active Reports : &nbsp; </label>
                <span>{counts?.reports?.activeReports}</span>
              </p>
              <p>
                <label>Blocked Reports : &nbsp; </label>
                <span>{counts?.reports?.blockedReports}</span>
              </p>
            </div>
          </div>
          {!!counts ? (
            <div id="chart">
              <Apexxcahrt
                options={dataApex1?.options}
                series={[
                  counts?.reports?.activeReports,
                  counts?.reports?.blockedReports,
                ]}
                type="donut"
                height={350}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="main1" style={{ marginRight: "0px" }}>
          <div class="cardds three">
            <div className="title">
              <h4>Products</h4>
              {/* <i class="fad fa-archive"></i> */}
              <div className="imgIcon">
                <img src={BoxIcon} />
              </div>
            </div>
            <hr />
            <div className="content">
              <p>
                <label>Total Products : &nbsp; </label>
                <span>{counts?.products?.activeProducts}</span>
              </p>
            </div>
          </div>
          {!!counts ? (
            <div id="chart">
              <Apexxcahrt
                options={dataApex2?.options}
                series={[counts?.products?.activeProducts]}
                type="donut"
                height={350}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Dash;

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import { DeleteProductAPI } from "../../services/Product_api_block"


export default function ProductDelete({ product, allTable, handleDelete }) {
  const [open, setOpen] = useState(false);
  const [deleteArr, setDeleteArr] = useState(product);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    handleClose();
    if (product) {
      DeleteProductAPI(product._id)
        .then((data) => {
          if (data) {
            setOpen(false);
            let inx = allTable.findIndex((i) => product.title == i.title);
            let temp = [...allTable];
            temp.splice(inx, 1);
            handleDelete(temp);
          }
        })
        .catch((err) => {

        })
    }
  }

  return (
    <div>
      <button className="bttn btn-danger"
        variant="outlined" onClick={handleClickOpen}>
        <DeleteForeverSharpIcon
        />
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure that you want to delete this product?</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={() => handleYes()}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}




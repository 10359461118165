import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  changePasswordService,
  changeUserInfoService,
} from "../../services/UserApi";
import { notify } from "../../utils/notify";
import { setUser } from "../../redux/reducers/userSlie";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user?.user?.token);
  const [firstName, setFirstName] = useState(
    user?.admin?.firstName ? user?.admin?.firstName : ""
  );
  const [lastName, setLastName] = useState(
    user?.admin?.lastName ? user?.admin?.lastName : ""
  );
  const [email, setEmail] = useState(
    user?.admin?.email ? user?.admin?.email : ""
  );
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [editProfileLoader, setEditProfileLoader] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);

  const handleSubmit = () => {
    let obj = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };

    if (currentPassword?.length > 0) {
      if (password.length !== 0) {
        let objPassword = {
          currentPassword: currentPassword,
          newPassword: password,
        };
        changePassword(objPassword);
        changeInfo(obj);
      } else {
        notify("Please enter new password as well");
      }
    } else {
      changeInfo(obj);
    }
  };

  const changeInfo = (obj) => {
    setEditProfileLoader(true);
    changeUserInfoService(obj)
      .then(({ data }) => {
        setEditProfileLoader(false);
        notify(data?.msg, "success");
        let user = {
          admin: data?.data,
          token: token,
        };
        dispatch(setUser(user));
        setPassword("");
        setCurrentPassword("");
      })
      .catch(() => {
        setEditProfileLoader(false);
      });
  };

  const changePassword = (obj) => {
    setPasswordLoader(true);
    changePasswordService(obj)
      .then(({ data }) => {
        setPasswordLoader(false);
        notify(data?.msg, "success");
        notify(data?.error);
        setPassword("");
        setCurrentPassword("");
      })
      .catch((err) => {
        setPasswordLoader(false);
      });
  };

  const onClose = () => {
    setPassword("");
    setCurrentPassword("");
    setShowCurrentPassword(false);
    setShowPassword(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          <h5 style={{ fontSize: "24px", marginTop: "4%" }}>Edit profile</h5>

          <Grid container spacing={2} sx={{ marginTop: "4%" }}>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>

          <TextField
            sx={{ marginTop: "4%", width: "100%" }}
            id="outlined-basic"
            label="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Grid container spacing={2} sx={{ marginTop: "2%" }}>
            <Grid item xs={6} sx={{ position: "relative" }}>
              <TextField
                id="outlined-basic"
                label="Current Password"
                variant="outlined"
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              {!showCurrentPassword ? (
                <VisibilityIcon
                  sx={{
                    position: "absolute",
                    right: "10px",
                    top: "30px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{
                    position: "absolute",
                    right: "10px",
                    top: "30px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                />
              )}
            </Grid>
            <Grid item xs={6} sx={{ position: "relative" }}>
              <TextField
                id="outlined-basic"
                label="New Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!showPassword ? (
                <VisibilityIcon
                  sx={{
                    position: "absolute",
                    right: "10px",
                    top: "30px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{
                    position: "absolute",
                    right: "10px",
                    top: "30px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </Grid>
          </Grid>

          <button className="editProfileSave" onClick={handleSubmit}>
            {editProfileLoader || passwordLoader
              ? "Saving Changes..."
              : "Save Changes"}
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

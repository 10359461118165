import { Container } from "@mui/material";
import React from "react";
import LogoFroth from "./../../assets/images/logoFroth.png";

function PrivacyPolicy() {
  return (
    <div className="privacy">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5%",
        }}
      >
        <img src={LogoFroth}></img>
      </div>

      <h1>Terms and Conditions</h1>
      <h2>General:</h2>
      <p>
        Welcome to the Froth (“Mobile Application” or “Website” or "Site”).
        These Terms and Conditions (“Terms”) sets for the agreement between
        Orphan Boards, its parent, subsidiaries and other affiliated companies
        (collectively, "Orphan Boards" or "we") and each user ("you" or "user")
        governing the use by you of the Mobile Application/Website. Please read
        these Terms carefully before using the Mobile Application/Website.
        Orphan Boards offers, as a social marketplace, a platform where users
        may buy and sell surf related products and post surf and weather
        conditions and interact socially regarding any and all things surf
        related. By using the Mobile Application/Website, you agree to be bound
        by these Terms. If you do not agree to the terms and conditions
        contained herein, you may not access or otherwise use the Mobile
        Application/Website.
      </p>
      <p>
        We may change or update these Terms so please check this page regularly.
        We do not represent or warrant that the information on our Mobile
        Application/Website is accurate, complete or current. This includes
        pricing and availability information. We reserve the right to correct
        any errors or omissions, and to change or update information at any time
        without giving prior notice.
      </p>
      <h2>Age Restrictions:</h2>
      <p>
        The Mobile Application/Website is intended to be used by adults, as
        defined under any applicable law in your jurisdiction. If you are a
        minor, you need permission of your parent or legal guardian in order to
        use the Service. In this case, the legal representative is liable for
        any acts committed by the minor. Please, if you are a minor and you do
        not have your legal representative’s permission, stop using the Mobile
        Application/Website. You further warrant that you are an adult under the
        applicable law. In the event that the information you provide in this
        regard is not truthful, Orphan Boards shall not be liable as it cannot
        with certainty verify the age of the users. To sell or purchase products
        using the Mobile Application/Website, users who are minors under
        applicable law will need the specific permission of their parent or
        legal guardian.
      </p>
      <h2>Account Creation:</h2>
      <p>
        To use or access certain functionalities of the Mobile
        Application/Website you need to register, by providing us your name,
        address, phone number, gender, age and e-mail address. We may also
        require other information including profile picture, and user name for
        purposes of making available that information on the Mobile
        Application/Website You agree to provide accurate, current, and complete
        account information about you as may be prompted by the registration
        and/or login form on the Site.
      </p>
      <h2>Account Security:</h2>
      <p>
        By opening an account, you accept and assume all liability that may
        arise in any activity that occurs under your username and password. You
        are responsible for maintaining the confidentiality of the username and
        password that you designate during the account registration process, and
        you are fully responsible for all activities that occur under your
        username and password. You agree to (a) immediately notify Orphan Boards
        of any unauthorized use of your username or password or any other breach
        of security, and (b) ensure that you exit from your account at the end
        of each session. Orphan Boards will not be liable for any loss or damage
        arising from your failure to comply with this provision. You should use
        particular caution when accessing your account from a public or shared
        computer so that others are not able to view or record your password or
        other personal information. If you share your computer with others, you
        may wish to consider disabling your auto-sign in feature.
      </p>
      <h2>Features of the Service:</h2>
      <p>The Service will allow some of the following features:</p>
      <p>
        (a) Sharing with all Froth users’ information, images, photos as well as
        other data or personal information by posting a listing.
      </p>
      <p>
        (b) Putting products on sale as a seller user and making offers as a
        buyer user in order to purchase or negotiate the sale of products.
      </p>
      <p>
        (c) Interacting with other users of Froth through a private chat to
        coordinate the purchase of listed products.
      </p>
      <p>(d) Posting current surf conditions for various beaches.</p>
      <p>(e) Interact with other surfers socially using the platform.</p>
      <h2>Copyright & Trademarks:</h2>
      <p>
        Unless otherwise noted, all materials, including images, illustrations,
        designs, icons, photographs, video clips, written and any other
        materials that appear as part of the Mobile Application/Website on the
        Mobile Application/Website are copyrights, trademarks, trade dress
        and/or other forms of intellectual property owned, controlled, and/or
        used under license or with other legal authority by Orphan Boards. The
        Mobile Application/Website as a whole are protected by copyright and
        trade dress, all worldwide rights, titles and interest in and to which
        are owned by Orphan Boards. You may not post, distribute or reproduce in
        any way any copyrighted material, trademarks or other proprietary
        information unless under fair use or obtaining written consent of the
        owner of such proprietary rights.
      </p>
      <h2>License:</h2>
      <p>
        Subject to the terms of this Agreement, grants you a nontransferable,
        non-exclusive, license to use the Site and Site for your personal,
        noncommercial use. In addition, you hereby grant Orphan Boards a
        non-exclusive license in perpetuity to use all copyrights, trademarks
        and any other materials you may own and/or display on the Mobile
        application/Website for the purpose of our marketing or social media
        use. However, Orphan Boards assumes no responsibility for the
        intellectual property rights of the content that seller users include in
        the listings of its products.
      </p>
      <h2>Disclaimer of Warranties; Limitation of Liability:</h2>
      <p>
        Orphan Boards does not assume any responsibility nor warrants the
        products sold by using the Site, or the accuracy of surf
        conditions,weather reports, and other surf related information provided
        by users of the site. All information of the products advertised on the
        Mobile Application/Website has been built and published by the user
        which is selling the product. Consequently, Orphan Boards cannot
        guarantee the quality of the same, as well as the veracity of the images
        and/or descriptions published by the seller users. However, if we are
        made aware of any inappropriate listings or behavior by a user, we may
        elect to remove such posting and/or terminate their the user’s account.{" "}
      </p>
      <p>
        The transaction takes places and is decided exclusively between the
        buyer and seller, and Orphan Boards only provides use of the Mobile
        Application/Website for advertising purposes. Any claim or dispute that
        may arise between buying and selling users must be settled solely
        between them, and all users agree to hold Orphan Boards harmless.{" "}
      </p>
      <p>
        We do not guarantee, represent or warrant that your use of our Mobile
        Application/Website will be uninterrupted, timely, secure or error-free.
        You agree that from time to time we may remove the Mobile
        Application/Website for indefinite periods of time or cancel the Service
        at any time, without notice to you. You expressly agree that your use
        of, or inability to use, the Service is at your sole risk. The Service
        and all products and Mobile Application/Website delivered to you through
        the Service are (except as expressly stated by us) provided 'as is' and
        'as available' for your use, without any representation, warranties or
        conditions of any kind, either express or implied, including all implied
        warranties or conditions of merchantability, merchantable quality,
        fitness for a particular purpose, durability, title, and
        non-infringement.
      </p>
      <p>
        In no case shall Orphan Boards, our directors, officers, employees,
        affiliates, agents, contractors, interns, suppliers, service providers
        or licensors be liable for any injury, loss, claim, or any direct,
        indirect, incidental, punitive, special, or consequential damages of any
        kind, including, without limitation lost profits, lost revenue, lost
        savings, loss of data, replacement costs, or any similar damages,
        whether based in contract, tort (including negligence), strict liability
        or otherwise, arising from your use of any of the service or any
        products procured using the service, or for any other claim related in
        any way to your use of the service or any product, including, but not
        limited to, any errors or omissions in any content, or any loss or
        damage of any kind incurred as a result of the use of the Service or any
        content (or product) posted, transmitted, or otherwise made available
        via the service, even if advised of their possibility. Because some
        states or jurisdictions do not allow the exclusion or the limitation of
        liability for consequential or incidental damages, in such states or
        jurisdictions, our liability shall be limited to the maximum extent
        permitted by law.
      </p>
      <h2>Prohibited Items:</h2>
      <p>
        Orphan Boards prohibits the listing or sale of any items that are
        illegal under any applicable law, statute, ordinance or regulation at a
        local, state, provincial, national or international level. Some examples
        of items that are not allowed include, but are not limited to the
        following:
      </p>
      <div style={{ marginLeft: "10px" }}>
        <p>• Photos that do not show the item clearly.</p>
        <p>• Internet photos not taken or owned by yourself.</p>
        <p>
          • Items infringing on trademarks, copyrights or any other IP rights of
          third parties.
        </p>
        <p>• Item is perishable (food etc.) or live animals.</p>
        <p>• Advertisements.</p>
        <p>
          • Photo or text containing a reference to another website, mobile
          application or merchant.
        </p>
        <p>• Items that are defective or of poor quality.</p>
        <p>• An item listed as "free" but is not intended to be given away.</p>
        <p>• There is no realistic price set for the item.</p>
        <p>• Duplicated listings</p>
        <p>
          • Other items that are specifically prohibited include:
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <p>o Alcoholic Beverages</p>
            <p>o Blood, Bodily Fluids and Body Parts</p>
            <p>o Burglary Tools</p>
            <p>
              o Controlled chemical substances (such as cadmium, mercury, acids)
            </p>
            <p>o Counterfeit currency, stamps or coins</p>
            <p>o Counterfeit Products</p>
            <p>o Databases containing personal data</p>
            <p>o Electronic Surveillance Equipment</p>
            <p>o Embargoed Goods</p>
            <p>o Government, official and Transit Uniforms, IDs and Licenses</p>
            <p>o Illegal Drugs and Drug Paraphernalia</p>
            <p>o All types of illegal Site and products</p>
            <p>o Hacking tools</p>
            <p>o Hazardous Materials</p>
            <p>o Fireworks, Destructive Devices and Explosives</p>
            <p>
              o Identity Documents, Personal Financial Records and Personal
              Information (in any form, including mailing lists).
            </p>
            <p>o Items received through Government assistance</p>
            <p>o Items which encourage or facilitate illegal activity</p>
            <p>o Lottery Tickets, Sweepstakes Entries and Slot Machines</p>
            <p>o Obscene Material and all types of Pornography</p>
            <p>o All type of offensive material</p>
            <p>o Pesticides</p>
            <p>o Pictures or images that contain frontal nudity</p>
            <p>o Police and Other Security Forces Badges and Uniforms</p>
            <p>o Prescription Drugs, medicines, supplements and Devices</p>
            <p>o Recalled items</p>
            <p>
              o Sexually suggestive clothing including but not limited to
              underwear, lingerie, and erotic costumes
            </p>
            <p>o Stocks and Other Securities</p>
            <p>o Stolen Property</p>
            <p>o Tobacco Products (including vapes and e cigarettes)</p>
            <p>o Used Cosmetics</p>
            <p>
              o Weapons and related items (such as firearms, firearm parts and
              magazines, ammunition, BB and pellet guns, tear gas, stun guns,
              switchblade knives, and martial arts weapons).
            </p>
          </div>
        </p>
      </div>
      <p>
        In addition, Orphan Boards reserves the right to remove any listings
        that we consider inappropriate, unfit for posting, or unrelated to the
        action sports industry in any way. Our policies are often based on
        applicable laws and regulations, although in some cases, we may make
        listing decisions based on input from our users and our own discretion,
        especially for dangerous or sensitive items. If you see an item that
        violates these Terms please send details regarding the item to
        support@orphanboards.com.
      </p>
      <h2>Prohibited Uses:</h2>
      <p>
        As a condition of use, you promise not to use the Mobile
        Applicatoin/Website, specifically the chat feature, for any purpose that
        is unlawful or prohibited by these Terms, or any other purpose not
        intended by Orphan Boards. We allow you to chat with other users and
        post on the Mobile Application/Website solely for the purpose of buying
        and selling surf related products or to post surf conditions for local
        beaches. Including the following, but not limited to, you agree not to
        use the Mobile Application/Website or the chat feature of the Mobile
        Application/Website for any of the following purposes:
      </p>
      <div style={{ marginLeft: "10px" }}>
        <p>
          1) To abuse, harass, threaten, impersonate or intimidate any person;
        </p>
        <p>
          2) To post or transmit, or cause to be posted or transmitted, any
          visual content or communications that are libelous, defamatory,
          obscene, pornographic, abusive, offensive, profane or that infringes
          any copyright or other right of any person;
        </p>
        <p>
          3) For any purpose that is not permitted under the laws of the
          jurisdiction where you are accessing the Mobile Application/Website;
        </p>
        <p>
          4) To post or transmit, or cause to be posted or transmitted, any
          communication designed or intended to obtain password, account or
          private information from any other user on the Site;
        </p>
        <p>
          5) To create or transmit unwanted ‘spam’ to any person or any URL;
        </p>
        <p>6) To post copyrighted content that does not belong to you; </p>
        <p>
          7) You agree not to use any robot, spider, scraper or other automated
          means to access or use the Mobile Application/Website for any purpose
          without our express written permission. Additionally, you agree that
          you will not: (i) take any action that imposes, or may impose in our
          sole discretion an unreasonable or disproportionately large load on
          our infrastructure; (ii) interfere or attempt to interfere with the
          proper working of the Mobile Application/Website or any activities
          conducted on the Mobile Application/Website; (iii) bypass any measures
          we may use to prevent or restrict Mobile Application/Website access;
        </p>
        <p>
          8) To advertise to, or solicit, any user to buy or sell any third
          party products or Mobile application/Website, or to use any
          information obtained from the Mobile Application/Website in order to
          contact, advertise to, solicit, or sell to any user without their
          prior explicit consent.
        </p>
      </div>
      <p>
        We are not liable for any content or communications posted or
        transmitted through the Mobile Application/Website by another user. To
        report a suspected abuse of the Mobile Application/Website or a breach
        of the Terms please send written notice to Orphan Boards by email to:
        support@orphanboards.com. You are solely responsible for your
        interactions with other users of the Mobile Application/Website. Orphan
        Boards has no obligation to monitor disputes between you and other
        users.
      </p>
      <h2>Force Majeure:</h2>
      <p>
        In case of force majeure, we are not obliged to fulfil our obligations.
        In that case we may either suspend our obligations for the duration of
        the state of force majeure or definitely repudiate the agreement.
      </p>
      <p>
        Events of force majeure are all circumstances external to our will and
        control that render the respect of our obligations completely or partly
        impossible. Such events include, but are not limited to, strikes, fire,
        disruption of energy supplies or telecommunication networks or
        communication systems and/or the temporary down-time of the Mobile
        Application/Website, or the unavailability of certain aspects of the
        Mobile Application/Website.
      </p>
      <h2>Indemnification:</h2>
      <p>
        You agree to indemnify, defend and hold harmless Orphan Boards and our
        subsidiaries, affiliates, partners, officers, directors, agents,
        contractors, licensors, service providers, subcontractors, suppliers,
        interns and employees, harmless from any claim or demand, including
        reasonable attorneys’ fees, made by any third-party due to or arising
        out of your breach of these Terms or the documents they incorporate by
        reference, or your violation of any law or the rights of a third-party.
      </p>
      <h2>Severability:</h2>
      <p>
        In the event that any provision of these Terms is determined to be
        unlawful, void or unenforceable, such provision shall nonetheless be
        enforceable to the fullest extent permitted by applicable law, and the
        unenforceable portion shall be deemed to be severed from these Terms,
        such determination shall not affect the validity and enforceability of
        any other remaining provisions.
      </p>
      <h2>Entire Agreement and Waiver:</h2>
      <p>
        The failure of us to exercise or enforce any right or provision of these
        Terms shall not constitute a waiver of such right or provision. These
        Terms and any policies or operating rules posted by us on the Mobile
        Application/Website or in respect to the Mobile Application/Website
        constitutes the entire agreement and understanding between you and us
        and govern your use of the Mobile Application/Website, superseding any
        prior or contemporaneous agreements, communications and proposals,
        whether oral or written, between you and us (including, but not limited
        to, any prior versions of the Terms). Any ambiguities in the
        interpretation of these Terms shall not be construed against the
        drafting party.
      </p>
      <h2>Changes to Terms:</h2>
      <p>
        We reserve the right to modify the Terms at our sole discretion, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the Mobile Application/Website. If we
        make material changes to this policy, we will notify you here that it
        has been updated. Continued use of the Mobile Application/Website
        following such changes shall constitute your acceptance of such changes
        and agreement to be bound by the revised Terms.
      </p>
      <h2>Applicable Law and Jurisdiction:</h2>
      <p>
        These Terms and any separate agreements whereby we provide you on the
        Mobile Application/Website shall be governed by the laws of the State of
        California. The parties agree exclusive jurisdiction shall be in San
        Diego County, California for any and all issues arising out of the Terms
        or any related documents or transactions, or to the applicable
        jurisdiction in case of contracting with other consumers to purchase
        goods.
      </p>
      <h2>Contact Information:</h2>
      <p>
        Orphan Boards
        <br />
        P.O. Box 212111
        <br />
        Chula Vista, CA 91921
        <br />
        <a>support@orphanboards.com</a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;

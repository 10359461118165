import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";

// For upadting/deleting chat
import Button from "@mui/material/Button";
import { notify } from "../../utils/notify";
import { EditMsgServices, deleteMsgServices } from "../../services/ReportApi";
const Popup = ({ MsgObj, handleClose, onDelete, onEdit }) => {
  const [editSpinner, setEditSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState(false);
  const [text, setText] = useState(MsgObj?.text);
  const handleDelete = () => {
    setDeleteSpinner(true);
    deleteMsgServices(MsgObj._id)
      .then(({ data }) => {
        if (data?.code == 200) {
          onDelete();
          notify("Message deleted.", "success");
          handleClose();
        } else {
          notify("Something went wrong");
        }
      })
      .catch(() => {
        notify("Something went wrong");
      })
      .finally(() => {
        setDeleteSpinner(false);
      });
  };

  const handleEdit = () => {
    let obj = {
      conversationId: MsgObj.conversationId,
      messageId: MsgObj._id,
      text,
    };
    setEditSpinner(true);
    EditMsgServices(obj)
      .then(({ data }) => {
        if (data.code == 200) {
          onEdit({ ...MsgObj, text });
          notify("Message Updated.", "success");
          handleClose();
        } else {
          notify("Something went wrong");
        }
      })
      .catch(() => {
        notify("Something went wrong");
      })
      .finally(() => {
        setDeleteSpinner(false);
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        top: 0,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => {
        handleClose();
      }}
    >
      <div
        style={{
          width: "90%",
          padding: "50px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!MsgObj?.image ? (
          <>
            <textarea
              className="editMsg"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              style={{ height: "150px" }}
              disabled={deleteSpinner || editSpinner}
            />
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => handleEdit()}
                variant="contained"
                style={{
                  background: "#77C606",
                  marginLeft: "0px",
                  width: "46%",
                  height: "50px",
                }}
                disabled={deleteSpinner || editSpinner}
              >
                {editSpinner ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Edit"
                )}
              </Button>
              <Button
                onClick={() => handleDelete()}
                variant="contained"
                style={{
                  background: "#C63206",
                  width: "46%",
                  height: "50px",
                }}
                disabled={deleteSpinner || editSpinner}
              >
                {deleteSpinner ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={MsgObj?.image}
              style={{
                height: "200px",
                width: "150px",
                margin: "0 auto",
                objectFit: "cover",
              }}
            />
            <Button
              onClick={() => handleDelete()}
              variant="contained"
              className="buttonMsgdelete"
              style={{
                background: "#C63206",
                width: "150px",
                marginTop: "5%",
                margin: "0 auto",
                height: "50px",
              }}
              disabled={deleteSpinner || editSpinner}
            >
              {deleteSpinner ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;

import { HTTP_CLIENT } from '../utils/interceptor'
import { Endpoint_Productapi_block, Endpoint_DeleteProduct } from '../utils/endpoint'
const Productapi_blockData = (id) => {
  if (id) {
    return HTTP_CLIENT.put(Endpoint_Productapi_block.product_blockApi + id)
  }
}

const DeleteProductAPI = (obj) => {
  return HTTP_CLIENT.put(Endpoint_DeleteProduct.deleteProduct + obj)
}

export {
  Productapi_blockData,
  DeleteProductAPI
}


import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loadering from "../../../assets/images/loading.gif";
import { SearchTagsAll, SearchTagsAllById } from "../../services/SearchTags";
import { FormControlUnstyledContext, Pagination } from "@mui/material";
import TextField from "@mui/material/TextField";
import { UserapiData } from "../../services/UserApi";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GetreportedUser, GetreportedChat, reportUserAPI } from "../../services/ReportApi";
import UserView from "../users/UserView";

function ReportFeedBack(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1)
  const [open, setOpen] = useState(props.open);
  const [User, setUser] = useState([]);
  const [SelectUsers, setSelectUser] = useState();
  const [catID, setcATid] = useState("");
  const [responseTags, setresponseTags] = useState([]);
  const [loader5, setLoader5] = useState(false);
  const [delId, setdelId] = useState();

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };


  const getReportedProfiles = () => {
    setOpen(true);
    reportUserAPI(currentPage)
      .then((data) => {
        setOpen(false);
        setresponseTags(data.data.data?.reports)
        setCount(data?.data?.data?.count);
      })
      .catch((err) => {

      })
  }

  useEffect(() => {
    getReportedProfiles()
  }, [])


  const downloadData = [];
  useEffect(() => {
    // setresponseTags([])
    if (props.title != "Reported Chats") {
      searchtgsa(currentPage);

    }
    else {
      reportedUser(currentPage)
    }

  }, [props.title, currentPage]);



  const UserViews = (resp) => {
    setLoader5(true);
    setdelId(resp);
  };
  const findId = (value) => {
    let id = "";
    let clone = [...User];
    clone.forEach((element) => {
      let findIndexId = clone.findIndex((ii) => ii.firstName == value);
      id = clone[findIndexId]._id;
      setcATid(id);
    });
    if (id === "allCategory") {
      searchtgsa();
    } else {
      // searchtagsById(id);
    }
  };

  const searchtgsa = () => {
    setresponseTags([])
    getReportedProfiles();
  };
  const reportedUser = () => {
    setresponseTags([])
    // GetreportedUser([]);
    setOpen(true);
    GetreportedChat(currentPage)
      .then(({ data }) => {
        if (data) {
          setCount(data?.data?.count)
          setresponseTags(data.data?.reports);
        }
        setOpen(false);
      })
      .catch((err) => {
      });
  };


  const downloadExcel = () => {
    downloadData.length = 0;
    responseTags?.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Time: moment(items.createdAt).format("h:mm:ss a"),
        Date: moment(items.createdAt).format("Do MMM YY"),
        ReportTo: items?.reportedUserId?.firstName,
        BeachName: items?.beachId?.name,
        ReportBy: items?.userId?.firstName,
        Reason: items?.reason,
      };
      downloadData.push(tempObj);
    });
    setOpen(false);

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Complain");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Complain.xlsx");
  };
  const downloadExcelRePORT = () => {
    downloadData.length = 0;
    responseTags?.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Time: moment(items.createdAt).format("h:mm:ss a"),
        Date: moment(items.createdAt).format("Do MMM YY"),
        ReportTo: items?.reportedUserId?.firstName,
        Product: items?.productId?.title,
        ReportBy: items?.userId?.firstName,
        Reason: items?.reason,
      };
      downloadData.push(tempObj);
    });
    setOpen(false);

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Complain");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Complain.xlsx");
  };

  return (
    <div>
      <div className="users_table">
        <div className="titlee">
          <h2>{props.title}</h2>
        </div>
        {open ?
          <div className="loaadds">
            <img src={Loadering} className="loaderr" />
          </div> :
          <>

            <TableContainer component={Paper}>
              <div className="excle_btn">
                <Button
                  id="dwnlProfiles"
                  className="excle_innbtn dowloadButton"
                  onClick={() => {
                    props.title != "Reported Chats" ?
                      downloadExcel() :
                      downloadExcelRePORT()
                    // setOpen(true);
                  }}
                >
                  {props.title != "Reported Chats" ? "Download Reported Profiles" : "Download Reported Chats"} <FileDownloadIcon />
                </Button>
              </div>

              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tableRowData">
                    {/* <TableCell className="tableHeadings" align="center">SR</TableCell> */}
                    <TableCell className="tableHeadings" align="center">Report By</TableCell>
                    <TableCell className="tableHeadings" align="center">Report To</TableCell>

                    {props.title == "Reported Profiles" ? " " : <TableCell className="tableHeadings" align="center">{props.title != "Reported Chats" ? "Beach" : "Product"}</TableCell>}
                    <TableCell className="tableHeadings" align="center">Reason</TableCell>
                    <TableCell className="tableHeadings" align="center" colSpan={4}>
                      Action
                    </TableCell>

                    {/* <TableCell align="center">
                  Action
                </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseTags?.reverse().map((item, index) => {
                    return (
                      <>
                        <TableRow>
                          {/* <TableCell className="tableCellData" align="center">{index + 1}</TableCell> */}
                          <TableCell className="tableCellData" align="center">
                            {item?.userId?.firstName}
                          </TableCell>
                          <TableCell className="tableCellData" align="center">
                            {item?.reportedUserId?.firstName}
                          </TableCell>
                          {props.title == "Reported Profiles" ? " " : <TableCell className="tableCellData" align="center">
                            {item?.productId ? item?.productId?.title : item?.beachId?.name}
                          </TableCell>
                          }

                          <TableCell className="tableCellData" align="center">
                            {item?.reason}
                          </TableCell>
                          <TableCell className="tableCellData" align="center">
                            <button
                              className="bttn btn-success"
                              type="button"
                              onClick={() => {
                                UserViews(item);
                              }}
                            >
                              <VisibilityIcon />
                            </button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              {loader5 ?
                <UserView resp={delId} close={setLoader5} feedss /> : ""}
            </TableContainer>
            <div className="paginationContainer">
              <Pagination
                count={Math.ceil(count / 10)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                sx={{
                  '& .MuiPaginationItem-root': {
                    height: '50px',
                    width: "50px",
                    fontSize: "1rem"
                  },
                  '& .Mui-selected': {
                    backgroundColor: '#488fbf !important',
                    color: 'white',
                    border: "none"
                  },
                }}
              />
            </div>
          </>
        }


      </div>
    </div>
  );
}

export default ReportFeedBack;

import { HTTP_CLIENT } from "../utils/interceptor";
import {
  Endpoint_product_review,
  Endpoint_review_by_product,
  Endpoint_report_review,
  Endpoint_review_by_report,
  Endpoint_get_all_beaches
} from "../utils/endpoint";
const product_review = (query) => {
  if (query) {
    return HTTP_CLIENT.post(Endpoint_product_review.product_review, query);
  }
};
const review_by_product = (query) => {
  return HTTP_CLIENT.get(Endpoint_review_by_product.review_by_product + query);
};
const report_review = (query) => {
  if (query) {
    return HTTP_CLIENT.post(Endpoint_report_review.report_review, query);
  }
};
const review_by_report = (query) => {
  return HTTP_CLIENT.get(Endpoint_review_by_report.review_by_report + query);
};
const get_all_beaches = () => {
  return HTTP_CLIENT.get(Endpoint_get_all_beaches.get_all_beaches);
};

export { product_review, review_by_product,report_review,review_by_report,get_all_beaches };

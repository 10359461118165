import { React, useState, useEffect } from "react";
import { DelProductimg, DelReportimg } from "../services/product_img_del";
import Avatar from "../../assets/images/avatar.png";
import Fileadd from "../../assets/images/file_add.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import Imagedelete from "../components/products/Imagedelete";
import { notify } from "../utils/notify";
import Loaadings from "../../assets/images/ImageLoad.gif";
import ProgressiveImage from "react-progressive-graceful-image";

function ImagesUpload({
  title,
  data,
  propicdel,
  setProducturl,
  productUrl,
  setProductImagelist,
  productImagelist,
  updata,
  setUpdata,
  loader3,
  setLoader3,
  loader,
  setLoader,
}) {
  const [productImage, setProductImage] = useState([]);
  const [index_img, setindexx] = useState();
  const [id_img, setIdnew] = useState();
  const [own_img, setimggg] = useState();

  const del_product_pic = (index_img, id_img, own_img) => {
    setLoader(true);
    if (title === "Report") {
      DelReportimg(id_img, own_img)
        .then(({ data }) => {
          let changing = [...productUrl];
          changing.splice(index_img, 1);
          setProducturl(changing);
          setLoader(false);
          notify("Image deleted", "success");
          propicdel(index_img, id_img);
        })
        .catch((err) => {});
    } else {
      DelProductimg(id_img, own_img)
        .then(({ data }) => {
          let changing = [...productUrl];
          changing.splice(index_img, 1);
          setProducturl(changing);
          setLoader(false);
          notify("Image deleted", "success");
          propicdel(index_img, id_img);
        })
        .catch((err) => {});
    }
  };
  const upDate = (e, index) => {
    let file = e.target.files[0];
    if (file) {
      let url = URL.createObjectURL(file);
      let cloneArray = [...productImagelist];
      cloneArray[index] = file;
      setProductImagelist(cloneArray);
      setProductImage(cloneArray);
      let cloneArrayUrl = [...productUrl];
      cloneArrayUrl[index] = url;
      setProducturl(cloneArrayUrl);
    }
  };
  const uploadFilesempty = (e) => {
    let file = e.target.files[0];
    if (file.type.includes("image")) {
      if (file) {
        let url = URL.createObjectURL(file);
        let cloneArray = [...productImage];
        let cloneArrayList = [...productImagelist];

        cloneArray.push(file);
        cloneArrayList.push(file);
        setProductImagelist(cloneArrayList);
        setProductImage(cloneArray);
        let cloneArrayUrl = [...productUrl];
        cloneArrayUrl.push(url);
        setProducturl(cloneArrayUrl);
      }
    } else {
      notify("Please select just image type", "error");
    }
  };
  return (
    <div className="listingupdate">
      <label>{title} Images</label>
      <br />
      {productUrl.length > 0 ? (
        <div className="imgflex">
          {productUrl.map((i, idex) => {
            return (
              <div key={idex} style={{ marginRight: "10px" }} className="rell">
                <label
                  htmlFor={`upload_image${idex}`}
                  className="spaace"
                  style={{ cursor: "pointer" }}
                >
                  {/* <img src={i} alt={Avatar} /> */}
                  <ProgressiveImage src={i} placeholder={Loaadings}>
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading" : " loaded"}`}
                        src={src}
                        alt={Avatar}
                        width={"100%"}
                        style={{ borderRadius: "2px" }}
                      />
                    )}
                  </ProgressiveImage>
                </label>
                <input
                  type="file"
                  id={`upload_image${idex}`}
                  name={`upload_image${idex}`}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    upDate(e, idex);
                    setUpdata(true);
                  }}
                />
                <div
                  className="absol"
                  onClick={() => {
                    setindexx(idex);
                    setIdnew(data?._id);
                    setimggg(i);
                    setLoader3(true);
                  }}
                >
                  <HighlightOffIcon />
                </div>
              </div>
            );
          })}
          {productUrl.length < 4 && (
            <div style={{ marginRight: "10px" }}>
              <label
                htmlFor={`upload_image`}
                className="spaace"
                style={{ cursor: "pointer" }}
              >
                <img src={Fileadd} alt={Avatar} />
              </label>
              <input
                type="file"
                id={`upload_image`}
                name={`upload_image`}
                style={{ display: "none" }}
                accept="image/*"
                onChange={(e) => {
                  uploadFilesempty(e);
                  setUpdata(true);
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="imgflex">
          <div style={{ marginRight: "10px" }}>
            <label
              htmlFor={`upload_image`}
              className="spaace"
              style={{ cursor: "pointer" }}
            >
              <img src={Fileadd} alt={Avatar} />
            </label>
            <input
              type="file"
              id={`upload_image`}
              name={`upload_image`}
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => {
                uploadFilesempty(e);
                setUpdata(true);
              }}
            />
          </div>
        </div>
      )}

      {loader ? <CircularProgress className="circulerr" /> : " "}
      {loader3 ? (
        <Imagedelete
          close={setLoader3}
          runfun={del_product_pic}
          inndeex={index_img}
          idlex={id_img}
          selimg={own_img}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ImagesUpload;

import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { notify } from "../../utils/notify";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../assets/images/avatar.png";
import Fileadd from "../../../assets/images/file_add.png";
import { UPdateProduct } from "../../services/product.services";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DelProductimg } from "../../services/product_img_del";
import Imagedelete from "../../components/products/Imagedelete";
import ImagesUpload from "../../utils/ImagesUpload";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ProductUpdate(props) {
  const { updstate, propicdel } = props;
  const data = props.id;
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(true);
  const [title, settitle] = useState(data.title);
  const [description, setdescription] = useState(data.description);
  const [condition, setcondition] = useState(data.condition);
  const [productImage, setProductImage] = useState([]);
  const [loader3, setLoader3] = useState(false);
  const [selete_permanent, setselete_permanent] = useState(false);
  const [productUrl, setProducturl] = useState(data.images);
  const [productImagelist, setProductImagelist] = useState(data.images);
  const [newUrl, setNewUrl] = useState([]);
  const [newFile, setNewFilet] = useState([]);
  const [idddd, setidddd] = useState(data._id);

  const changingshift = () => {
    setProductImage(productImagelist);
  };
  const [updata, setUpdata] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleSubmit = () => {
    // if(productImage.)
    
    for (let i = 0; i < productImagelist.length; i++) {
      if (productImagelist[i].name) {
        newFile.push(productImagelist[i]);
      } else {
        newUrl.push(productImagelist[i]);
      }
    }

    let obj = {
      title: title,
      description: description,
      images: JSON.stringify(newUrl),
      uploadFile: newFile,
    };

    UPdateProduct(data?._id, obj)
      .then(({ data }) => {

        updstate("Update Successfully");
        handleClose();
        props.update(idddd, obj, productUrl);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.data.code == "400") {
          notify(err.response.data.message);
        }
        setLoader(false);
      });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div className="maindivuser">
          <h2>Product Data</h2>
          <hr></hr>
          <div className="useruPDATE">
            <div className="listingupdate">
              <TextField
                label="Title"
                id="margin-none"
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                  if (data.title != e.target.value) {
                    setUpdata(true);
                  } else {
                    setUpdata(false);
                  }
                }}
              />
            </div>
            <div className="listingupdate">
              <TextField
                label="Description"
                id="margin-none"
                value={description}
                onChange={(e) => {
                  setdescription(e.target.value);
                  if (data.description != e.target.value) {
                    setUpdata(true);
                  } else {
                    setUpdata(false);
                  }
                }}
              />
            </div>
          </div>

          <ImagesUpload
            title="Product"
            data={data}
            propicdel={propicdel}
            productUrl={productUrl}
            setProducturl={setProducturl}
            setProductImagelist={setProductImagelist}
            productImagelist={productImagelist}
            setUpdata={setUpdata}
            updata={updata}
            loader3={loader3}
            setLoader3={setLoader3}
            setLoader={setLoader}
            loader={loader}
          />
          {updata ? (
            <button
              onClick={() => {
                handleSubmit();
                setLoader(true);
              }}
              className="bttn btn btnupdate"
            >
              Update
            </button>
          ) : (
            ""
          )}
        </div>
      </Dialog>
    </div>
  );
}
export default ProductUpdate;

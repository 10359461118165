import React, { useEffect, useState } from 'react';
import ApexChart from "react-apexcharts";


function Chart({ tides }) {


  const [data, setData] = useState({
    series: [
      {
        name: "series 1",
        data: [],
      },
    ],
    options: {
      categories: [],
      labels: [],
      dataLabels: {
        enabled: true
      },
      chart: {
        type: "area",
      },
      stroke: {
        curve: "smooth",
      },

      legend: {
        horizontalAlign: "left",
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,

            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const getData = (ob) => {
    let obj = {
      ...data,
      series: [{ data: ob?.map((item) => item?.tideHeight_mt) }],
      options: {
        ...data.options,
        xaxis: {
          categories: ob?.map((item) => item?.tideTime)
        }
      }
    }
    setData(obj)
  }

  useEffect(() => {
    getData(tides);
  }, [tides])

  return (
    <div>
      <div>
        <ApexChart
          options={data.options}
          type={"area"}
          series={data.series}
        />
      </div>
    </div>
  )
}

export default Chart
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, favBeaches }) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <h5>Favourite Beaches</h5>

          <div style={{ marginTop: "5%" }}>
            {favBeaches.length ? (
              favBeaches?.map((item, inx) => {
                return (
                  <div>
                    <span>{(inx + 1)+ ".  "}</span>
                    {item?.placeName}
                  </div>
                );
              })
            ) : (
              <div>No Favourite Beaches</div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

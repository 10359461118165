import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import BlockSharpIcon from "@mui/icons-material/BlockSharp";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { notify } from "../../utils/notify";
import { useSelector } from "react-redux";
import ProductBlock from "./ProductBlock";
import ProductUnblock from "./ProductUnblock";
import ProductView from "./ProductView";
import CircularProgress from "@mui/material/CircularProgress";
import Loadering from "../../../assets/images/loading.gif";
import ProductUpdate from "./ProductUpdate";
import Caution from "../../../assets/images/caution.jpg";
import XLSX from "xlsx";
import { Button } from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import moment from "moment";
import SortIcon from "@mui/icons-material/Sort";
import InfinitScroll from "react-infinite-scroll-component";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";
import FormDialog from "../dialog";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { GetAllProducts, ProductapiData } from "../../services/ProductApi";
import { product_review } from "../../services/Veiwers";
import ViewedPopUp from "./viewedPopUp";
import DetailsIcon from "@mui/icons-material/Details";
import ViewListIcon from "@mui/icons-material/ViewList";
import ProductDelete from "./ProductDelete";
import AddProduct from "./../../../shared/components/modals/addProduct";
import { Box, Pagination } from "@mui/material";

export default function ProductsTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [dataUser, setdataUser] = useState([]);
  const [netcheckk, setNetcheckk] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(props.open);
  const [respchange, setrespchange] = useState(false);
  const user = useSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [reviewedOn, setReviewedOn] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const handleOpenProduct = () => {
    setOpenAddProduct(true);
  };
  const handleCloseProduct = () => {
    setOpenAddProduct(false);
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };

  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [blockedyes, setBlockedyes] = useState("");
  const [change, setChange] = useState(false);
  // const [pgNo, setpgNo] = useState(1);
  // const [perPg, setperPg] = useState(10);
  const [fdate, setFdate] = useState("");
  const [lDate, setLdate] = useState("");
  const [excelarray, setdownloadarray] = useState([]);

  const [state, setState] = useStateWithCallbackLazy({
    pgNo: 1,
    perPg: 10,
    loader: true,
  });
  const [delId, setdelId] = useState();
  const [addId, setaddId] = useState();
  const [updated, setUpdated] = useState(false);
  const [searchMenu, setsearchMenu] = useState("");

  const [sortedField, setSortedField] = React.useState(null);
  let sortedProducts = [...dataUser];
  const [Totalarray, settoTalarray] = useState("");
  const [fileterbool, setFilterBool] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const downloadData = [];

  const closeModal = () => setFilterBool(false);
  const updatestate = (message) => {
    setUpdated((prev) => !prev);
    notify(`${message}`, "success");
  };
  const handleupdate = (id, obj, productUrl) => {
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id);
    changing[findelement].title = obj.title;
    changing[findelement].description = obj.description;
    changing[findelement].condition = obj.condition;
    changing[findelement].images = productUrl;

    setdataUser(changing);
  };
  const picdel = (index, id) => {
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id);
    changing[findelement].images.splice(index, 1);
    setdataUser(changing);
  };
  const handleblocked = (id_block) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_block);
    if (changing[findelement].isApproved == true) {
      statusblock = false;
    } else {
      statusblock = true;
    }
    changing[findelement].isApproved = statusblock;
    setdataUser(changing);
    setBlockedyes("");
  };
  const Userupdated = (resp) => {
    setLoader4(true);
    setaddId(resp);
  };
  const ViewedPopUps = (resp) => {
    setLoader5(true);
    setaddId(resp);
  };
  const ProductViews = (resp) => {
    if (resp._id && user?.user?.user?.admin?.role == "admin") {
      setLoader(true);
      setdelId(resp);
      let today = moment(new Date()).format("YYYY-MM-DD");
      let time = moment().format("h:mm:ss a");
      // let obj = {
      //   productId: resp?._id,
      //   adminId: user?.user?.user?.admin?.id,
      //   dateReviewed: today,
      //   timeReviewed: time,
      // };
      // product_review(obj).then(({ data }) => {
      // });
    } else {
      setLoader(true);
      setdelId(resp);
    }
  };
  const addagain = (id) => {
    setLoader1(true);
    setaddId(id);
  };
  const blockUser = (id) => {
    setLoader2(true);
    setdelId(id);
  };
  const addagainblock = (id) => {
    setLoader3(true);
    setaddId(id);
  };
  const netcheck = () => {
    if (!window.navigator.onLine) {
      notify("Network not found");
      setOpen(false);
      setNetcheckk(true);
    }
  };
  const emptyforce = () => {
    setdataUser([]);
    setState({
      ...state,
      pgNo: 1,
    });
    setUpdated((prev) => !prev);
  };

  useEffect(() => {
    netcheck();
    let query = `?pageNo=${currentPage}&perPage=${10}${
      searchMenu ? `&search=${searchMenu}` : ""
    }${fdate ? `&startDate=${fdate}` : ""}${lDate ? `&endDate=${lDate}` : ""}${
      reviewedOn ? `&reviewed=${reviewedOn}` : ""
    }`;

    ProductapiData(query)
      .then(({ data }) => {
        setCount(data?.data?.count);
        setdataUser(data?.data?.products);
        setState({
          ...state,
          loader: false,
        });
        if (data) {
          setOpen(false);
        }
        if (data && data.data && data.data?.products?.length) {
          let newww = [...dataUser];
          data.data.products.forEach((element) => {
            let findAnyelement = newww.findIndex((ii) => ii._id == element._id);
            if (findAnyelement < 0) {
              newww = [...newww, element];
            }
          });
          // setdataUser(newww);
          settoTalarray(data.data.count);
        }
      })
      .catch((err) => {
        setState({
          ...state,
          loader: false,
        });
      });
  }, [updated, change, currentPage, reviewedOn]);
  const downloadapi = () => {
    setDownloadLoader(true);
    GetAllProducts()
      .then(({ data: { data } }) => {
        downloadExcel(data);
      })
      .finally(() => {
        setDownloadLoader(false);
      });
  };
  const filterfun = () => {
    setSortedField(null);
  };
  const getApiCall = () => {
    setState({
      ...state,
      pgNo: state.pgNo + 1,
      loader: true,
    });
    setChange((prev) => !prev);
  };

  const downloadExcel = (resp) => {
    downloadData.length = 0;
    resp.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Created_Date: moment(items.createdAt).format("MMM Do YY"),
        Title: items.title,
        Description: items.description,
        // Condition: items.condition,
        Inventory: items.sold ? "Out of Stock" : "In stock",
        // Live_Status: items.isApproved ? "Verified" : "Unverified",
        ReportBy: items?.user?.email,
        Review: items?.isReviewed ? "Yes" : "No",
        Reviewer: items?.reviewId
          ? items?.reviewId?.adminId?.firstName +
            " " +
            items?.reviewId?.adminId?.lastName
          : "No Review",
        Review_Date: items?.reviewId
          ? moment(items?.reviewId?.timeReviewed).format("Do MMM YY")
          : "No Review",
        Review_Time: items?.reviewId
          ? moment(items?.reviewId?.timeReviewed).format("h:mm:ss a")
          : "No Review",
      };
      downloadData.push(tempObj);
    });

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Products");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Products Data.xlsx");
  };

  if (sortedField !== null) {
    sortedProducts.sort((a, b) => {
      if (sortedField == "firstName") {
        if (a.user[sortedField] < b.user[sortedField]) {
          return -1;
        }
        if (a.user[sortedField] > b.user[sortedField]) {
          return 1;
        }
      } else {
        if (a[sortedField] < b[sortedField]) {
          return -1;
        }
        if (a[sortedField] > b[sortedField]) {
          return 1;
        }
      }
      return 0;
    });
  }
  const FilterDate = (firstdte, lstdte) => {
    netcheck();
    setState(
      {
        ...state,
        pgNo: 1,
      },
      (va) => {
        emptyforce();
        setFdate(firstdte);
        setLdate(lstdte);
        closeModal();
        setOpen(true);
        setrespchange(true);
      }
    );
  };

  const handleDelete = (arr) => {
    setdataUser(arr);
  };

  const handleAdd = (data) => {
    let temp = [...dataUser];
    temp.unshift(data);
    setdataUser(temp);
    sortedProducts = temp;
  };

  return (
    <div className="users_table">
      <div className="titlee">
        <h2>Products List</h2>
      </div>
      <div className="search_view">
        <input
          type="search"
          placeholder="Search..."
          autoComplete="off"
          value={searchMenu}
          onChange={(e) => {
            setsearchMenu(e.target.value);
            emptyforce();
          }}
        ></input>
      </div>
      {open ? (
        <div className="loaadds">
          <img src={Loadering} className="loaderr" />
        </div>
      ) : (
        <>
          <div
            className="excle_btn "
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <Button
                className="addReportButton"
                onClick={() => handleOpenProduct()}
              >
                Add Product
              </Button>
            </div>
            <div>
              <Button
                className={
                  reviewedOn
                    ? "reviewedOnButton reviewedButton"
                    : "reviewedButton"
                }
                onClick={() => setReviewedOn(!reviewedOn)}
              >
                Reviewed only
              </Button>
              {!fdate ? (
                <Button
                  className="excle_innbtn dowloadButton filterBtn"
                  onClick={() => setFilterBool(true)}
                >
                  <FilterAltIcon />
                </Button>
              ) : (
                <Button
                  className="excle_innbtn"
                  onClick={() => {
                    setState(
                      {
                        ...state,
                        pgNo: 1,
                      },
                      () => {
                        emptyforce();
                        setFdate("");
                        setLdate("");
                        setStartDate(null);
                        setEndDate(null);
                        setrespchange(false);
                        setOpen(true);
                      }
                    );
                  }}
                >
                  <RotateLeftIcon />
                </Button>
              )}
              <Button
                className="excle_innbtn dowloadButton"
                onClick={() => {
                  downloadapi();
                }}
              >
                {downloadLoader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ) : (
                  <>
                    Download Excel <FileDownloadIcon />
                  </>
                )}
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            {/* <InfinitScroll
            dataLength={dataUser.length}
            next={getApiCall}
            hasMore={true}
            loader={state.loader ? <LinearProgress /> : ""}
          > */}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tableRowData">
                  {/* <TableCell className="tableHeadings" align="center">SR</TableCell> */}
                  <TableCell className="tableHeadings" align="center">
                    Date
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("createdAt")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Title{" "}
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("title")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Description
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Inventory
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Price
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    User
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Email
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Reviewed
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Time
                  </TableCell>
                  {/* {user?.user?.user?.admin?.role != "admin" ? (
                    <TableCell  className="tableHeadings" align="center">Re-Viewed by</TableCell>
                  ) : (
                    ""
                  )} */}
                  <TableCell
                    className="tableHeadings"
                    align="center"
                    colSpan={4}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts.map((dats, i) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell className="tableCellData" align="center">{i + 1} </TableCell> */}
                        <TableCell className="tableCellData" align="center">
                          {moment(dats.createdAt).format("Do MMM YY")}
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          {dats.title}{" "}
                        </TableCell>

                        <TableCell className="tableCellData" align="center">
                          {dats.description}
                        </TableCell>
                        <TableCell
                          className="tableCellData indv"
                          align="center"
                        >
                          <span
                            className={dats.sold ? "out_red" : "avail_green"}
                          >
                            {dats.sold ? "Out of stock" : "Available"}
                          </span>
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          {dats.price}
                        </TableCell>
                        {respchange ? (
                          <TableCell className="tableCellData" align="center">
                            {dats.user &&
                              dats.user.map((item, i) => {
                                return <>{item.firstName}</>;
                              })}
                          </TableCell>
                        ) : (
                          <TableCell className="tableCellData" align="center">
                            {dats.user && dats.user.firstName+" "+dats.user.lastName}
                          </TableCell>
                        )}
                        {respchange ? (
                          <TableCell
                            className="tableCellData"
                            align="center"
                            sx={{ maxWidth: "fit-content" }}
                          >
                            {dats.user &&
                              dats.user.map((item, i) => {
                                return <>{item.email}</>;
                              })}
                          </TableCell>
                        ) : (
                          <>
                            <TableCell
                              className="tableCellData"
                              align="center"
                              sx={{ maxWidth: "fit-content" }}
                            >
                              {dats.user && dats.user.email}
                            </TableCell>
                            <TableCell className="tableCellData" align="center">
                              {dats?.user && dats?.isReviewed ? "Yes" : "No"}
                            </TableCell>
                          </>
                        )}
                        <TableCell className="tableCellData" align="center">
                          {moment(dats?.createdAt).fromNow()}
                        </TableCell>
                        {/* {user?.user?.user?.admin?.role != "admin" ? (

                          <TableCell className="tableCellData" align="center">
                            <ViewListIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                ViewedPopUps(dats);
                              }}
                            />
                          </TableCell>
                        ) : (
                          ""
                        )} */}
                        <TableCell className="tableCellData" align="right">
                          <button
                            className="bttn btn-success"
                            type="button"
                            onClick={() => {
                              ProductViews(dats);
                            }}
                          >
                            <VisibilityIcon />
                          </button>
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          <button
                            onClick={() => {
                              Userupdated(dats);
                            }}
                            className="bttn btn btnupdate"
                            type="button"
                          >
                            <EditSharpIcon />
                          </button>
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          <ProductDelete
                            product={dats}
                            allTable={dataUser}
                            handleDelete={handleDelete}
                          />
                        </TableCell>
                        {/* <TableCell className="tableCellData" align="left">
                          <div className="statuss">
                            <label>
                              {dats.isApproved ? "Verified" : "Unverified"}
                            </label>
                            <Switch
                              onClick={() => {
                                dats.isApproved
                                  ? addagainblock(dats._id)
                                  : blockUser(dats._id);
                              }}
                              checked={dats.isApproved}
                              color={dats.isApproved ? "success" : "warning"}
                            />
                          </div>
                        </TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {/* </InfinitScroll> */}

            {loader ? <ProductView resp={delId} close={setLoader} /> : ""}
            {loader2 ? (
              <ProductBlock
                id={delId}
                updstate={updatestate}
                blocked={handleblocked}
                close={setLoader2}
              />
            ) : (
              ""
            )}
            {loader3 ? (
              <ProductUnblock
                id={addId}
                unblocked={handleblocked}
                updstate={updatestate}
                close={setLoader3}
              />
            ) : (
              ""
            )}
            {loader4 ? (
              <ProductUpdate
                id={addId}
                updstate={updatestate}
                update={handleupdate}
                close={setLoader4}
                propicdel={picdel}
              />
            ) : (
              ""
            )}
            {loader5 ? (
              <ViewedPopUp
                resp={addId}
                // updstate={updatestate}
                // update={handleupdate}
                close={setLoader5}
                // propicdel={picdel}
              />
            ) : (
              ""
            )}

            {open ? <img src={Loadering} className="loaderr" /> : ""}
            {netcheckk ? (
              <div className="loaderr">
                <img src={Caution}></img>
                <h3>Your connection is not available</h3>
              </div>
            ) : (
              ""
            )}
            {loader3 ? (
              <ProductUnblock
                id={addId}
                unblocked={handleblocked}
                updstate={updatestate}
                close={setLoader3}
              />
            ) : (
              ""
            )}
            <AddProduct
              open={openAddProduct}
              handleClickOpen={handleOpenProduct}
              handleClose={handleCloseProduct}
              handleAdd={handleAdd}
            />
          </TableContainer>

          <div className="paginationContainer">
            <Pagination
              count={Math.ceil(count / 10)}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  height: "50px",
                  width: "50px",
                  fontSize: "1rem",
                },
                "& .Mui-selected": {
                  backgroundColor: "#488fbf !important",
                  color: "white",
                  border: "none",
                },
              }}
            />
          </div>
        </>
      )}
      {netcheckk ? (
        <div className="loaderr">
          <img src={Caution}></img>
          <h3>Your connection is not available</h3>
        </div>
      ) : (
        ""
      )}
      <FormDialog
        open={fileterbool}
        handleClose={closeModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        callback={FilterDate}
      />
    </div>
  );
}

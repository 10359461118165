import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { notify } from '../../utils/notify';
import { useSelector } from 'react-redux';
import { AddProductAPI } from '../../services/ProductApi';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClickOpen, handleClose, handleAdd }) {
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [fullAddress, setFullAddress] = useState(null);
  const [location, setLocation] = useState(null);
  const user = useSelector((state) => state.user.user);


  const onClose = () => {
    setAddress("");
    setCoordinates({ lat: null, lng: null });
    setLoader(false);
    setSelectedFile(false);
    setPreviewImage(false);
    setTitle("");
    setLocation(null);
    setFullAddress(null);
    setDescription("")
    setPrice(0)
    handleClose();

  }


  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      let city, state, country;
      for (let i = 0; i < results[0].address_components.length; i++) {
        const component = results[0].address_components[i];
        if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        } else if (component.types.includes('country')) {
          country = component.long_name;
        }
      }


      setFullAddress({
        city: city,
        state: state,
        description: selectedAddress
      })

      setLocation({
        type: 'Point',
        miles: 30,
        coordinates: [latLng?.lng, latLng?.lat]
      })

      setAddress(selectedAddress);
      setCoordinates(latLng);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };


  const handleSubmit = () => {
    if (title?.length == 0 || description.length == 0 || price == 0 || selectedFile === null ) {
      notify("Please enter all enteries")
    } else {

      let obj = {
        title: title,
        description: description,
        price: price,
        location: JSON.stringify(location?location:{
          type: 'Point',
          miles: 30,
          coordinates: [-122.446,37.733]
        }),
        address: JSON.stringify(fullAddress?fullAddress:{city: 'San Francisco',state:"CA",description: 'San Francisco, CA, USA'}),
        user: user?.admin?.id,
        files: selectedFile
      }

      setLoader(true)

      AddProductAPI(obj)
        .then(({ data: { data } }) => {
          setLoader(false)
          handleAdd(data)
          onClose();
        })
        .catch((err) => {
          console.log("err", err)
          setLoader(false)
        })
    }


  }

  const handlePrice = (event) => {
    const numericValue = event.target.value.replace(/\D/, ''); // Remove non-numeric characters
    setPrice(numericValue);
  };


  return (
    <div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ padding: "24px" }}>
          <div style={{ fontSize: "20px", textAlign: "center" }}>
            Add Product
          </div>
          <div>
            Title
          </div>
          <input className='addProductInput' value={title} onChange={(e) => setTitle(e.target.value)} />
          <div>
            Description
          </div>
          <textarea className="textAreaProduct" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
          <div>
            Price
          </div>
          <input className='addProductInput' value={price} onChange={handlePrice} />
          <div>
            Location
          </div>

          <div>
            <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: 'Search Places...',
                      className: 'addProductInput',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

          </div>

          <ImageUploader selectedFile={selectedFile} previewImage={previewImage} setSelectedFile={setSelectedFile} setPreviewImage={setPreviewImage} />

          <Button className="reportPost" variant='contained' onClick={() => handleSubmit()}>
            {loader ? "loading" : "Post Product"}
          </Button>


        </DialogContent>
      </Dialog>
    </div>
  );
}



const ImageUploader = ({ selectedFile, previewImage, setSelectedFile, setPreviewImage }) => {


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setPreviewImage(null);
    }
  };

  const handleUpload = () => {
    // Here, you can perform the upload logic using the selectedFile
    // For example, you can send it to a server using axios or fetch
    console.log('Uploading file:', selectedFile);
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-input"
      />
      {previewImage ?
        <img src={previewImage} className='selectedImageUpload' />
        :
        <label htmlFor="file-input" className='imageUploaderLabel'>
          Select File
        </label>}

      {previewImage && <ClearIcon
        className="deleteIcon"
        style={{ color: "red" }}
        onClick={() => {
          setPreviewImage(null);
          setSelectedFile(null)
        }}
      />}


    </div>
  );
};


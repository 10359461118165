import React, { useState, useEffect } from "react";
import axios from "axios";
import { Field, Form, Formik, withFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../../assets/css/Auth.css";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../utils/notify";
import moment from 'moment';
import {
  registrationApi,
  AdminregistrationApi,
} from "../../services/RegistrationApi";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';



const LoginValidation = yup.object().shape({
  email: yup.string().email().required("Required"),
  password: yup
    .string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  age: "",
  gender: "",
  zipCode: "",
};
const initialValuess = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

function AddUser(props) {
  const [startDate, setStartDate] = useState(null);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [must1, setmust1] = useState();
  const [ViewsPass, setViewsPass] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [age, setAge] = useState(null);
  const [zip, setZip] = useState(null);
  const [gender, setGender] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);


  const handleAge = (age) => {
    var date = new Date(age);
    var formatedDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return formatedDate;
  };

  const signUpHandle = (obj) => {
    if (obj.age == "Invalid date" || obj.age == null ||
      obj.email == "" ||
      obj.firstName == "" ||
      obj.lastName == "" ||
      obj.gender == "" ||
      obj.password == "" ||
      obj.zipCode == "") {
      notify("Please Enter All Values")
    }
    else {
      setBtnLoader(true)
      registrationApi(obj)
        .then(({ data }) => {
          if (data) {
            setBtnLoader(false)
            setOpen(false);
            props.addNewTable(data.user);
            notify(`Thank you !`, "success");
            clossee();
          }
        })
        .catch((e) => {
          notify(e?.response?.data?.message);
          if (e.response.data.message === "Email already taken") {
            notify(`Email already taken!`);
            setOpen(false);
          }
          if (
            e.response.data.message ===
            "password must contain at least 1 letter and 1 number"
          ) {
            notify(`password must contain at least 1 letter and 1 number!`);
            // setmust1("password must contain at least 1 letter and 1 number!")
            setOpen(false);
          }
        });
    }

  };
  const AdminsignUpHandle = (obj) => {
    if (obj.firstName == "" || obj.email == "" || obj.lastName == "" || obj.password == "") {
      notify("Please enter all values")
    }
    else {
      setBtnLoader(true);
      AdminregistrationApi(obj)
        .then(({ data }) => {
          setBtnLoader(false);
          if (data?.error) {
            notify(data?.error);
          }
          else {
            if (data) {
              setOpen(false);
              props.addNewTable(data.user);
              notify(`Thank you !`, "success");
              clossee();
            }
          }
        })
        .catch((e) => {
          notify(e?.response?.data?.message);
          if (e?.response?.data?.message === "Email already taken") {
            notify(`Email already taken!`);

            setOpen(false);
          }
          if (
            e.response.data.message ===
            "password must contain at least 1 letter and 1 number"
          ) {
            notify(`password must contain at least 1 letter and 1 number!`);
            // setmust1("password must contain at least 1 letter and 1 number!")
            setOpen(false);
          }
          setOpen(false);

        });
    }
  };
  const { updstate } = props;
  const clossee = () => {
    updstate("New User Added");
  };
  const genders = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const passView = (val) => {
    if (val == 1) {
      setViewsPass(1);
    } else {
      setViewsPass(0);
    }
  };
  return (
    <>
      {props.title == "Admins" ?
        <>
          <div>
            <h5 style={{ fontSize: "24px", paddingLeft: "5%", marginTop: "4%" }}>Admin Data</h5>
            <div className="borderDiv"></div>
            <div className="addAdminGrid">
              <div style={{ width: "48%" }}>
                <TextField id="outlined-basic" label="First Name" variant="outlined" onChange={(e) => setFirstName(e.target.value)} />
              </div>
              <div style={{ width: "48%" }}>
                <TextField id="outlined-basic" label="Last Name" variant="outlined" onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>
            <div className="addAdminGrid" style={{ marginTop: "20px" }}>
              <div style={{ width: "48%" }}>
                <TextField
                  id="outlined-password-input"
                  label="Email"
                  type="email"
                  autoComplete="current-password"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div style={{ width: "48%" }}>
                <TextField
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="addNewBtn">
            <button
              disabled={false}
              className="addAdminBtn addNew" onClick={() => AdminsignUpHandle({
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password
              })}>
              {btnLoader ?
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress className="spinnerLoader" color="inherit" />
                </Box> : "New admin"}
            </button>
          </div>
        </>
        :
        <>
          <div>
            <h5 style={{ fontSize: "24px", paddingLeft: "5%", marginTop: "4%" }}>Create User</h5>
            <div className="borderDiv"></div>
            <div className="addAdminGrid">
              <div style={{ width: "48%" }}>
                <TextField id="outlined-basic" label="First Name" variant="outlined" onChange={(e) => setFirstName(e.target.value)} />
              </div>
              <div style={{ width: "48%" }}>
                <TextField id="outlined-basic" label="Last Name" variant="outlined" onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>
            <div className="addAdminGrid" style={{ marginTop: "20px" }}>
              <div style={{ width: "48%" }}>
                <TextField
                  id="outlined-password-input"
                  label="Email"
                  type="email"
                  autoComplete="current-password"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div style={{ width: "48%" }}>
                <TextField
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="addAdminGrid" style={{ marginTop: "20px" }}>
              <div style={{ width: "48%" }}>
                <TextField id="outlined-number" label="Zip Code" variant="outlined" type="number" onChange={(e) => setZip(e.target.value)} />
              </div>

              <div style={{ width: "48%" }}>
                <TextField
                  id="standard-select-currency genderr"
                  select
                  label="Gender"
                  // value={values?.gender}
                  // onChange={(}
                  // variant="standard"
                  required
                >
                  {genders.map((option) => (
                    <MenuItem key={option.value} value={option.value} onClick={() => setGender(option?.value)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

              </div>
            </div>
            <div className="addAdminGrid" style={{ marginTop: "20px", position: "relative" }}>
              {/* <div style={{ width: "48%" }}>
                <input className="calendarInput" type="date" id="birthday" style={{ fontWeight: "500", color: "grey" }} value={age} name="birthday" onChange={(e) => setAge(e.target.value)} />
              </div> */}
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
              />
              {/* <div style={{ position: "absolute", top: "13px", left: "222px" }}>
                <CalendarTodayIcon style={{ color: "grey" }} />
              </div> */}
            </div>
          </div>
          <div className="addNewBtn">
            <button
              disabled={false}
              className="addAdminBtn addNew" onClick={() => signUpHandle(
                {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  password: password,
                  deviceId: "123456789",
                  // age: (moment().diff(moment(handleAge(age), "DD-MM-YYYY"), 'years')).toString(),
                  age: moment(startDate).format('DD/MM/YYYY'),
                  zipCode: zip,
                  gender: gender,
                  location: {
                    coordinates: [-122.406417, 37.785834],
                  }
                }
              )}>
              {btnLoader ?
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress className="spinnerLoader" color="inherit" />
                </Box> : "Add User"}
            </button>
          </div>
        </>
      }

    </>
  );
}

export default AddUser;

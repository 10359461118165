import Dialog from "@material-ui/core/Dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { React, useEffect, useRef, useState } from "react";
import ScrollView from "react-inverted-scrollview";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import Loadering from "../../../assets/images/loading.gif";
import DefaultGroupAvatar from "../../../assets/images/mountain.png";
import { getGroupChat, getGroupMsgs } from "../../services/groupsApi";
import { notify } from "../../utils/notify";
import { DateHandler, DisplayMsg } from "./chatComponents";
import Popup from "./msgActionPopUp";
import NoSearch from "../../../assets/images/noSearch.png";
import { Message } from "./message";

function ChatView({ data, onClose }) {
  const [open, setOpen] = useState(true);
  const [loader, setLoader] = useState(true);
  const [modalInfo, setModalInfo] = useState({
    msg: null,
    open: false,
  });
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };
  const [conv, setConv] = useState(null);
  const [messages, setMessages] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  const pageNo = useRef(1);
  const maxPage = useRef(1);
  useEffect(() => {
    getGroupChat(data?._id)
      .then(({ data }) => {
        if (data?.code == 200) {
          let conversation = data?.data?.conversation;
          setConv(conversation);
          maxPage.current = data?.data?.pages;
          if (!!conversation._id) {
            let msgPosition = "right";
            let messages = data?.data?.messages?.map((item, inx) => {
              if (inx > 0) {
                if (
                  data?.data?.messages[inx]?.senderId?.id !=
                  data?.data?.messages[inx - 1]?.senderId?.id
                ) {
                  if (msgPosition == "right") {
                    msgPosition = "left";
                  } else {
                    msgPosition = "right";
                  }
                }
              }
              return {
                ...item,
                position: msgPosition,
              };
            });
            setMessages(messages);
          }
        } else {
          handleClose();
          notify("Something went wrong!");
        }
      })
      .catch(() => {
        handleClose();
        notify("Something went wrong!");
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const LoadMoreMsgs = (pageNo) => {
    setLoadMore(true);
    getGroupMsgs(conv._id, pageNo)
      .then(({ data }) => {
        if (data?.code == 200) {
          let msgs = data?.data;
          if (msgs && msgs?.length > 0) {
            let msgPosition = "right";
            let messages = msgs?.map((item, inx) => {
              if (inx > 0) {
                if (msgs[inx]?.senderId?.id != msgs[inx - 1]?.senderId?.id) {
                  if (msgPosition == "right") {
                    msgPosition = "left";
                  } else {
                    msgPosition = "right";
                  }
                }
              }
              return {
                ...item,
                position: msgPosition,
              };
            });
            setMessages((pre) => [...messages, ...pre]);
          }
        }
      })
      .finally(() => {
        setLoadMore(false);
      });
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="min-height">
          <div className="group-chat-header">
            <ProgressiveImage
              src={data.coverPhoto ? data.coverPhoto : DefaultGroupAvatar}
              placeholder={Loaadings}
            >
              {(src, loading) => (
                <img
                  className={`image${loading ? " loading" : " loaded"}`}
                  src={src}
                  alt={Avatar}
                  width={"100%"}
                  style={{
                    borderRadius: "25px",
                    width: "50px",
                    height: "50px",
                  }}
                />
              )}
            </ProgressiveImage>
            <div className="group-chat-text-div">
              <p className="group-edit-heading">{data?.title}</p>
            </div>
            <div className="pointer" onClick={handleClose}>
              <CancelIcon />
            </div>
          </div>
          {loader ? (
            <div className="member-loading-div">
              <div className="loaadds">
                <img src={Loadering} className="loaderr" />
              </div>
            </div>
          ) : (
            <>
              {messages?.length > 0 ? (
                <ScrollView
                  width={"100%"}
                  height={"74vh"}
                  onScroll={({ scrollTop, scrollBottom }) => {
                    if (scrollTop < 80) {
                      if (!loadMore && pageNo?.current < maxPage.current) {
                        pageNo.current++;
                        LoadMoreMsgs(pageNo.current);
                      }
                    }
                  }}
                  style={{
                    padding: "0px 20px",
                    borderTop: "1px solid #e3e3e3",
                  }}
                >
                  {loadMore && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="loaadds">
                        <img
                          src={Loadering}
                          className="loaderr"
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  )}
                  {messages?.map((item, inx) => {
                    return (
                      <Message
                        item={item}
                        previousMsg={inx > 0 ? messages[inx - 1] : null}
                        inx={inx}
                        onPress={(item) => {
                          setModalInfo({
                            msg: item,
                            open: true,
                          });
                        }}
                      />
                    );
                  })}
                </ScrollView>
              ) : (
                <div className="member-loading-div">
                  <img className="no-search" src={NoSearch} />
                  <p className="users-error-text" style={{ marginTop: "10px" }}>
                    No Messages Found!
                  </p>
                </div>
              )}
              {modalInfo?.open && (
                <Popup
                  MsgObj={modalInfo.msg}
                  handleClose={() => {
                    setModalInfo({
                      msg: null,
                      open: false,
                    });
                  }}
                  onDelete={() => {
                    setMessages((pre) => {
                      let msgs = pre?.filter(
                        (elem) => elem?._id != modalInfo?.msg?._id
                      );
                      return msgs;
                    });
                  }}
                  onEdit={(item) => {
                    setMessages((pre) => {
                      let msgs = pre?.map((elem) => {
                        if (elem?._id == modalInfo?.msg?._id) {
                          return item;
                        } else return elem;
                      });
                      return msgs;
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}
export default ChatView;

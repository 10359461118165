import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { React, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import DefaultGroupAvatar from "../../../assets/images/mountain.png";
import MemberCard from "./memberCard";

function GroupView({ data, onClose }) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div class="scrollbar scrollbar-lady-lips">
          <div class="force-overflow">
            <div className="lelel">
              <h2>Group Detail</h2>
              <div className="flexxview">
                <div className="group">
                  <ProgressiveImage
                    src={data.coverPhoto ? data.coverPhoto : DefaultGroupAvatar}
                    placeholder={Loaadings}
                  >
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading" : " loaded"}`}
                        src={src}
                        alt={Avatar}
                        width={"100%"}
                        style={{
                          borderRadius: "2px",
                          width: "140px",
                          height: "140px",
                          marginBottom: "50px",
                        }}
                      />
                    )}
                  </ProgressiveImage>
                </div>
                <div className="detaill">
                  <div className="reportview">
                    <h6 className="popUpHeading">Group Title</h6>
                    <p className="popUpDetail">{data.title}</p>
                  </div>
                  <hr />
                  <div className="reportview">
                    <h6 className="popUpHeading">Description</h6>
                    <p className="popUpDetail">{data.description}</p>
                  </div>
                  <hr />
                  <div className="reportview">
                    <h6 className="popUpHeading">Creator</h6>
                    <p className="popUpDetail">
                      {data?.adminId?.firstName + " " + data?.adminId?.lastName}
                    </p>
                  </div>
                  <div className="reportview">
                    <h6 className="popUpHeading">Members Count</h6>
                    <p className="popUpDetail">{data?.members?.length}</p>
                  </div>
                  <hr />
                  <div className="reportview">
                    <h6 className="popUpHeading">Members List</h6>
                  </div>
                  {data?.members?.map((item, index) => {
                    return <MemberCard item={item} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default GroupView;

import React from 'react';
import LogoFroth from "./../../assets/images/logoFroth.png";
import { Grid, useMediaQuery } from '@mui/material';
import Delete1 from "./../../assets/images/Delete1.png";
import Delete2 from "./../../assets/images/Delete2.png";
import Delete3 from "./../../assets/images/Delete3.png";
import Delete4 from "./../../assets/images/Delete4.png";

function DeleteAccount() {
  const isWidth900 = useMediaQuery("(max-width:900px)");

  return (
    <div className="privacy" style={{ background: "#f1f6f9" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5%",
        }}
      >
        <img src={LogoFroth} alt="Froth Logo" />
      </div>

      <h2>Delete Your Account (Instructions)</h2>
      <p>Please Note: Deleting your account will result in the permanent removal of all associated data, including chats, products, and reports. No account data will be retained on our side, and its restoration will be impossible.</p>

      <Grid container spacing={2} style={{ marginTop: "50px" }}>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p>
            1. Navigate to the home page.
          </p>
        </Grid>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={Delete1} className="screenshotImage" alt="Screenshot 1" />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "50px" }}>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
          <img src={Delete2} className="screenshotImage" alt="Screenshot 2" />
        </Grid>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
          <p>
            2. Click on the Menu Icon to open the drawer.
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "50px" }}>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p>
            3. Scroll to the bottom and click on the 'Delete Account' option.
          </p>
        </Grid>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={Delete3} className="screenshotImage" alt="Screenshot 3" />
        </Grid>
      </Grid>


      <Grid container spacing={2} style={{ marginTop: "50px" }}>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
          <img src={Delete4} className="screenshotImage" alt="Screenshot 4" />
        </Grid>
        <Grid item xs={isWidth900 ? 12 : 6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
          <p>
            4. Confirm by clicking 'Yes,' and your account will be permanently deleted.
          </p>
        </Grid>
      </Grid>

    </div >
  );
}

export default DeleteAccount;

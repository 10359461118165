import {HTTP_CLIENT} from '../utils/interceptor'
import {Endpoint,EndpointAdmin} from '../utils/endpoint'
const UPdateUser = (id,obj)=>{
  
    let formbody = new FormData();
    Object.keys(obj).forEach((key,inx)=>{
        formbody.append(key,obj[key])
        // console.log(key,obj,"checkk object")
    })
return HTTP_CLIENT.put(Endpoint.updateUser+id,formbody)
}
const UPdateAdmin = (id,obj)=>{
  
    let formbody = new FormData();
    Object.keys(obj).forEach((key,inx)=>{
        formbody.append(key,obj[key])
        // console.log(key,obj,"checkk object")
    })
return HTTP_CLIENT.put(EndpointAdmin.updateAdmin+id,formbody)
}
export {
    UPdateUser,
    UPdateAdmin
}
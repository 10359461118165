import { HTTP_CLIENT } from "../utils/interceptor";
import { DASHBOARD_ENDPOINTS, WEATHER_ENDPOINTS } from "../utils/endpoint";
const weatherDataService = async (beachId) => {
  return HTTP_CLIENT.get(WEATHER_ENDPOINTS.getData + beachId);
};

const singleDayWeatheService = async (beachId) => {
  return HTTP_CLIENT.get(WEATHER_ENDPOINTS.getCurrentInfo + beachId);
};

const getDashboardCounts = async (beachId) => {
  return HTTP_CLIENT.get(DASHBOARD_ENDPOINTS.getCounts);
};

export { weatherDataService, singleDayWeatheService, getDashboardCounts };

import { HTTP_CLIENT } from "../utils/interceptor";
import {
  Endpoint_Admin_Download,
  Endpoint_searchTagsAll,
  Endpoint_SearchTagsAllById,
} from "../utils/endpoint";
const SearchTagsAll = (query, fDate, lDate) => {
  if (fDate && fDate) {
    return HTTP_CLIENT.get(
      Endpoint_searchTagsAll.searchTagsAll +
        `?pageNo=${query}&perPage=10&startDate=${lDate}&endDate=${fDate}`
    );
  } else {
    return HTTP_CLIENT.get(
      Endpoint_searchTagsAll.searchTagsAll + `?pageNo=${query}&perPage=10`
    );
    // http://localhost:1337/api/v1/admin/get-users?pageNo=1&perPage=10&
  }
  // if (query) {
  //   // return HTTP_CLIENT.get(Endpoint_searchTagsAll.searchTagsAll+query)
  //   return HTTP_CLIENT.get(Endpoint_searchTagsAll.searchTagsAll + `?pageNo=${query}&perPage=10`)

  // }
  // else {
  //   return HTTP_CLIENT.get(Endpoint_searchTagsAll.searchTagsAll)

  // }
};
const SearchTagsAllById = (query) => {
  if (query) {
    return HTTP_CLIENT.get(
      Endpoint_SearchTagsAllById.SearchTagsAllById + query
    );
  }
};

const GetAllSearchTags = () => {
  return HTTP_CLIENT.get(Endpoint_Admin_Download.searches);
};

export { SearchTagsAll, SearchTagsAllById, GetAllSearchTags };

import { memo } from "react";
import { DateHandler, DisplayMsg } from "./chatComponents";

const MessageItem = ({ item, previousMsg, onPress, inx }) => {
  console.log("damn");
  return (
    <>
      <DateHandler previousMsg={previousMsg} currentMsg={item} inx={inx} />
      <DisplayMsg item={item} onPress={onPress} />
    </>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.item) == JSON.stringify(nextProps.item) &&
    prevProps?.previousMsg?._id == nextProps?.previousMsg?._id &&
    prevProps?.inx == nextProps?.inx
  );
};
export const Message = memo(MessageItem, arePropsEqual);

// MessageItem;

import { HTTP_CLIENT } from "../utils/interceptor";
import { Endpoint_Report } from "../utils/endpoint";
const UPdateReport = (id, obj) => {
  let formbody = new FormData();
  Object.keys(obj).forEach((key, inx) => {
    if (key == "newFiles") {
      obj[key].forEach((keyobj, sinx) => {
        formbody.append(`newFiles[${sinx}]`, keyobj);
      });
    }
    if (key == "uploadVideoFile") {
      obj[key].forEach((keyobj, sinx) => {
        formbody.append(`uploadVideoFile[${sinx}]`, keyobj);
      });
    } else {
      formbody.append(key, obj[key]);
    }
  });
  return HTTP_CLIENT.put(Endpoint_Report.updateReport + id, formbody);
};
export { UPdateReport };

import { React } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import AviPlaceholder from "../../../assets/images/aviPlaceholder.svg";
import { Button } from "@material-ui/core";

const MemberCard = ({ item, remove, add, onBtnPress, disabled }) => {
  let showButton = remove || add;
  let buttonTitle = remove ? "Remove" : "Add";
  return (
    <div className="member-card-div">
      <div className="member-card-profile">
        <ProgressiveImage
          src={item.profilePic ? item.profilePic : AviPlaceholder}
          placeholder={Loaadings}
        >
          {(src, loading) => (
            <img
              className={`image${loading ? " loading" : " loaded"}`}
              src={src}
              alt={Avatar}
              width={"100%"}
              style={{
                borderRadius: "2px",
                width: "140px",
                height: "140px",
                marginBottom: "50px",
              }}
            />
          )}
        </ProgressiveImage>
      </div>
      <p className="member-card-name">
        {item?.firstName + " " + item?.lastName}
      </p>
      {showButton && (
        <Button
          className={
            add ? "member-card-button" : "dowloadButton member-card-button"
          }
          variant={add ? "contained" : ""}
          onClick={() => {
            onBtnPress && onBtnPress();
          }}
          disabled={!!disabled}
        >
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

export default MemberCard;

import { useRef, useState } from "react";
import { notify } from "./notify";

export function useFilteredPagination(Api, filters, dataHandler) {
  const paginationData = useRef({
    isApiHit: false,
    lastPage: 0,
    currentPage: 0,
    id: 0,
  });

  const [state, setState] = useState({
    mainLoader: true,
    refreshing: false,
    data: [],
    mainData: [],
    renderedCurrentPage: 1,
    renderedLastPage: 1,
    netIssue: false,
  });

  const netcheck = () => {
    if (!window.navigator.onLine) {
      notify("Network not found");
      setState((pre) => {
        return {
          ...pre,
          netIssue: true,
        };
      });
      return true;
    }
    setState((pre) => {
      return {
        ...pre,
        netIssue: false,
      };
    });
    return false;
  };

  function checkIsFilter(payload) {
    let isFilter = false;

    for (let i = 0; i < filters?.length && !isFilter; i++) {
      let key = filters[i][0];
      let val = filters[i][1];
      if (JSON.stringify(payload[key]) != JSON.stringify(val)) {
        isFilter = true;
      }
    }
    return isFilter;
  }

  function HitApi(payload, pageNumber) {
    let isFilter = checkIsFilter(payload);

    if (!netcheck()) {
      setState((pre) => {
        return {
          ...pre,
          mainLoader: true,
        };
      });

      let pageNo = 1;
      if (!!pageNumber) {
        pageNo = pageNumber;
      }

      let params = {
        ...payload,
        page: pageNo,
      };

      paginationData.current.isApiHit = true;
      let id = new Date().getTime();
      paginationData.current.id = id;

      Api(params)
        .then(({ data }) => {
          if (id == paginationData.current.id) {
            if (data?.code == 200) {
              let tempData = !!dataHandler
                ? dataHandler(data?.data)
                : data?.data;

              let lastPage = Math.ceil(tempData.count / 10);
              if (!isFilter) {
                paginationData.current.lastPage = lastPage;
                paginationData.current.currentPage = pageNo;
              }

              setState((pre) => {
                let val = {
                  ...pre,
                  data: tempData.data,
                  renderedCurrentPage: pageNo,
                  renderedLastPage: lastPage,
                };
                if (!isFilter) {
                  val.mainData = tempData.data;
                }
                return val;
              });
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          setState((pre) => {
            return {
              ...pre,
              mainLoader: false,
            };
          });
          paginationData.current.isApiHit = false;
        });
    }
  }

  function handleFiltersRemoved() {
    setState((pre) => {
      return {
        ...pre,
        data: pre.mainData,
        renderedCurrentPage: paginationData.current.currentPage,
        renderedLastPage: paginationData.current.lastPage,
      };
    });
  }
  return [state, setState, HitApi, handleFiltersRemoved];
}

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useSelector } from "react-redux";
import { Userapi_blockData } from "../services/User_api_block";
import { Userapi_unblockData } from "../services/User_api_unblock";
import Loadering from "../../assets/images/loading.gif";
import { set } from "date-fns";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function ReportChat(props) {
  const { data, updstate, checkingChat } = props;
  const [open, setOpen] = React.useState(true);
  const [loading, setloading] = useState(false);
  const [indexxi, setindex] = useState("");
  const [blockedId, setBlockedId] = useState("");
  const [spinnerLoader, setSpinnerLoader] = useState(false)


  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };


  //6396c158620c4e5d9e61afc2
  //6396c13e620c4e5d9e61af8e

  const blockuser = (id) => {

    // let obj = {
    //   conversationId: "6391bc8c47c6fd2e14932c45",
    //   blockUserId: "637dfd3f39929a718f6dbd0c"
    // };

    let obj = {
      conversationId: checkingChat.conversationId,
      blockUserId: id
    };

    setloading(true);
    Userapi_blockData(obj)
      .then(({ data }) => {
        updstate("Blocked Successfully");
        setloading(false);
        handleClose();
      })
      .catch((err) => {
        handleClose();
      });
  };

  const Unblockuser = (id) => {
    setloading(true);
    Userapi_unblockData(id)
      .then(({ data }) => {
        updstate("Unblocked Successfully");
        setloading(false);
        handleClose();

      })
      .catch((err) => {
        handleClose();
      });
  };


  useEffect(() => {
    if (checkingChat.block_status.receiver.isBlocked) {
      setBlockedId(checkingChat.block_status.receiver.id)
    } else if (checkingChat.block_status.sender.isBlocked) {
      setBlockedId(checkingChat.block_status.sender.id)
    }
    else { }
  }, [checkingChat]);

  const handleBlock = (item) => {
    if (!(blockedId == item._id)) {
      let obj = {
        conversationId: checkingChat._id,
        blockUserId: item._id
      };

      setSpinnerLoader(true);
      Userapi_blockData(obj)
        .then(({ data }) => {
          updstate("Blocked Successfully");
          setSpinnerLoader(false);
          handleClose();
        })
        .catch((err) => {
          handleClose();
        });
    }
    else {
      let obj = {
        conversationId: checkingChat._id,
        blockUserId: item._id
      };
      setSpinnerLoader(true);
      Userapi_unblockData(obj)
        .then(({ data }) => {
          updstate("Unblocked Successfully");
          setSpinnerLoader(false);
          handleClose();

        })
        .catch((err) => {
          handleClose();
        });
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Block !!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select user which you want to
            {checkingChat.block_status.receiver.isBlocked || checkingChat.block_status.receiver.isBlocked ? " block or unblock!" : " block!"}
          </DialogContentText>
          <div className="flexii">
            {/* {data?.map((items, index) => {
              return (
                <>
                  <div
                    className={items?.isBlock ? "temsChat opaa" : "temsChat"}
                    onClick={() => {
                      setindex(index);
                      items?.isBlock
                        ? Unblockuser(items?._id)
                        : blockuser(items?._id);
                    }}
                  >
                    {items.profilePic ? (
                      <img src={items?.profilePic}></img>
                    ) : (
                      <span className="nameee custm">
                        {items.firstName.charAt(0)}
                      </span>
                    )}
                    <span>
                      {items?.firstName} {items.lastName}
                    </span>
                  </div>
                  {loading && index == 0 ? (
                    <div className="center spins">
                      <img src={Loadering} className="loaderr " />{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })} */}
            {data?.map((item) => {
              return (
                <>
                  {spinnerLoader ?
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress />
                    </Box>
                    :
                    <div className="reportCard" style={blockedId == item._id ? { opacity: "0.5" } : {}}
                      onClick={() => handleBlock(item)}
                    >
                      <div style={{ display: "flex", alignItems: 'center', }}>
                        <img src={item?.profilePic} className="imgReportCard" />
                      </div>
                      <div>
                        <div >{item?.firstName + " " + item?.lastName}</div >
                        <div style={{ fontSize: "10px" }}>{blockedId == item._id ? "Blocked" : ""}</div >
                      </div>
                    </div>}
                </>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? "" : <Button onClick={handleClose}>Close</Button>}
        </DialogActions>
      </Dialog>
    </div >
  );
}

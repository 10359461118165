import { Container } from "@mui/material";
import React from "react";
import LogoFroth from "./../../assets/images/logoFroth.png";

function PrivacyPolicy() {
  return (
    <div className="privacy">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "5%",
        }}
      >
        <img src={LogoFroth}></img>
      </div>

      <h1>Privacy Policy</h1>
      <h2>1. Our Commitment to Privacy </h2>
      <p>
        This notice describes our Privacy Policy. Our Privacy Policy explains
        how Orphan Boards (“ORPHAN BOARDS”) collects, uses, and protects the
        personal information obtained through Froth (“Mobile Application” or
        Website” or “Site”). By accessing or using the you are accepting the
        practices described in this Privacy Policy.
      </p>
      <h2>2. What Information is Collected</h2>
      <p>
        (a) Information You Provide to Us: We collect any information you enter
        on our Mobile Application/Website, or that you give us in any other way.
        You can choose not to provide certain information, but in doing such,
        you may not be able to take advantage of our Mobile Application/WebSite
        and features.
        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
          <p>
            (i) Personally Identifiable Information: Personally Identifiable
            Information (“PII”) is data about you or your business that is
            personally identifiable. Examples of personally identifiable
            information include name, address, email address, credit card
            number, photographs, or any other information that is not otherwise
            publicly available. We use PII that has been provided to us for
            business purposes such as to contact you, for billing, or to
            otherwise render our mobile application We retain User information
            until removal is requested. Anyone who has provided personally
            identifiable data to ORPHAN BOARDS may elect to have their data
            deleted from our files, or to be used only for the service
            requested, by following the instructions listed under Personally
            Identifiable Opt-Out later in this policy. We do not collect PII for
            purposes of maintaining our Mobile Applcation/Website.
          </p>
          <p>
            (ii) Non-Personally Identifiable Information: Non-Personally
            Identifiable Information (“NPII”) data is collected by ORPHAN BOARDS
            and our partners and is used for retargeting. We, and/or one or more
            of our partners may collect NPII from people who download our Mobile
            Application or visit our Website or people who visit our partners’
            websites. This data is anonymous, and can include information such
            as web pages you’ve viewed, date and time of page views, domain
            type, the physical location of your device, and your interactions to
            an ad delivered by us or our ad technology partners. In many cases,
            we will automatically collect certain non-personal information about
            your use of the Mobile Application/Website. We collect this
            information to ensure that the Mobile Application/Website functions
            properly. We may collect, among other things, information about your
            browser or device, Mobile Application/Website usage data,
            information through cookies, pixel tags and other technologies, and
            aggregated information. This information may include:
            <p style={{ marginLeft: "10px" }}>
              - Site usage data, such as the date and time the Site on your
              device accesses our servers. We may also collect information
              collected automatically through your browser or mobile device.. We
              may collect Media Access Control (MAC) address, computer type
              (Windows or Macintosh), screen resolution, device manufacturer and
              model, language, Internet browser type and version and the name
              and version of the Mobile Application/Website (such as the
              Website) you are using.
              <br />
              - The operating system you are using, the domain name of your
              Internet service provider and your "click path" through the Mobile
              Application/Website or the Site;
              <br />- IP address, which we may use for purposes such as
              calculating usage levels, diagnosing server problems and
              administering the Mobile Application/Website;
            </p>
          </p>
        </div>
      </p>
      <p>
        (b) Automatic Information: When you use the Mobile Application/Website,
        you disclose certain information, such as the physical location of your
        device, which we collect using satellite, cell phone towers or WiFi
        signals. In some instances, you may be permitted to allow or deny such
        collection and use of your device’s location, but it may affect your
        ability to use the Mobile Application/Website.{" "}
      </p>
      <p>
        (c) "Cookies”: Our Mobile application/Website use cookies, tracking
        pixels and related technologies to provide our site to users. Cookies
        are small data files that are served by our platform and stored on your
        device. They enable us to identify your device when you move between
        different Sites and websites, so that we can serve targeted advertising
        to you. Most browsers are set up to accept cookies, but you can change
        your settings to have your browser notify you when you receive a new
        cookie or to refuse to accept cookies.
      </p>
      <h2>3. How and When the Information is Used</h2>
      <p>
        The information we collect is used for administering our business
        activities. No PII is sold, gathered or used for any other purpose. We
        may additionally use the information to notify you about changes or
        improvements to our Mobile Application/Website, new Mobile
        Application/Website, or special offers. NPII may be used for analytics
        software, so that we may track visitors and provide better service and
        market our business effectively. We may also use any information you
        post on the Mobile application/Website, including any images or photos
        as well as other items associated with the post, for our own purposes,
        including but not limited to, Instagram, Twitter, Facebooks
        advertisements and any other digital advertisements.
      </p>
      <h2>4. How We Protect Your Information </h2>
      <p>
        The privacy and protection of your information is important to us. We do
        not make any PII available to third parties without your permission. To
        protect your PII, we take reasonable precautions and follow industry
        best practices to make sure it is not inappropriately lost, misused,
        accessed, disclosed, altered or destroyed.
      </p>
      <p>
        However, please note that any information you post or disclose through
        the Mobile Application/Website will become public and may be available
        to other users and the general public. We urge you to be very careful
        when deciding to disclose any information on the Mobile
        Application/Website.
      </p>
      <p>
        Please be aware your user name, your updated photos, and your posted
        information may be available to the Internet's general public, and
        anyone else who has accessed the Mobile application/Website while you
        participate in some services, such as publishing ads for products, so
        you should exercise discretion when using the Mobile
        Application/Website. Personal information disclosed by you may be
        collected by other users of such Mobile Application/Website and may
        result in unsolicited messages. We are not responsible for protecting
        such information that you may disclose to third parties through our
        Mobile Application/Website (e.g. sending your telephone number to
        another user through the Site).
      </p>
      <h2>5. Third Party Links</h2>
      <p>
        In general, the third-party providers used by us will only collect, use
        and disclose your information to the extent necessary to allow them to
        perform the Mobile Application/Website they provide to us. However,
        certain third-party service providers, such as payment gateways and
        other payment transaction processors, have their own privacy policies in
        respect to the information we are required to provide to them for your
        purchase-related transactions.
      </p>
      <p>
        In particular, remember that certain providers may be located in or have
        facilities that are located in a different jurisdiction than either you
        or us. So, if you elect to proceed with a transaction that involves the
        Website of a third-party service provider, then your information may
        become subject to the laws of the jurisdiction(s) in which that service
        provider or its facilities are located.
      </p>
      <p>
        When you click on links on our Mobile Application/Website, they may
        direct you away from our Mobile Application/Website. We are not
        responsible for the privacy practices of other websites and encourage
        you to read their individual privacy policies. If you visit a
        third-party website link from our Mobile Application/Website, you do so
        at your own risk.
      </p>
      <p>
        Once you leave our Mobile Application/Website, or are redirected to a
        third-party website, you are no longer governed by this Privacy Policy
        or our Mobile Application/Website’s Terms of Service.{" "}
      </p>
      <h2>6. Registration </h2>
      <p>
        To interact on the Mobile Application/Website you need to register, by
        providing us your name, address, phone number, gender, age and e-mail
        address. We may also require other information including profile
        picture, and user name for purposes of making available that information
        on the Mobile application/Website. You may visit some areas of the
        Mobile application/Website as a guest and remain anonymous, but you may
        not be able to access all the content and features of those areas
        without registering.
      </p>
      <h2>7. Who Has Access to the Information </h2>
      <p>
        Information about our users are important to us. Outside of our payment
        processors we will not disclose any PII to any other third party without
        first receiving your permission.
      </p>
      <p>
        We may access or disclose information about you, including the content
        of your communications, in order to: (a) comply with the law or respond
        to lawful requests or legal process; (b) protect our rights or property
        or our customers, including the enforcement of our agreements or
        policies governing your use of the Mobile Application/Website; or (c)
        act on a good faith belief that such access or disclosure is necessary
        to protect the personal safety of our employees, customers or the
        public. We may also disclose PII as part of a corporate transaction such
        as a merger or sale of assets.{" "}
      </p>
      <h2>8. Children </h2>
      <p>
        The Mobile Application/Website is not intended for use by children under
        the age of 13. ORPHAN BOARDS does not allow individuals under the age of
        13 to create an account, nor do we knowingly collect or use any personal
        information from such children. If you are under the age of 13, do not
        submit any information to our Mobile Application/Website. If we learn
        that we collected personal information from children under the age of
        13, we will take steps to delete that information as soon as possible.
      </p>
      <h2>9. How You Can Access Your Information </h2>
      <p>
        You can request access to all your personally identifiable information
        by sending an e-mail to support@orphanboards.com.
      </p>
      <h2>10. Consent </h2>
      <p>
        By using our Mobile Application/Website, you consent to the collection
        and use of your personal information as described in this Privacy
        Policy.
      </p>
      <h2>11. Opting-Out</h2>
      <p>
        (a) Personally Identifiable Information: If a member elects to provide
        ORPHAN BOARDS with personally identifiable data, he or she has the right
        to tell us to have such data deleted in its entirety at any time. Please
        note that if you choose to delete your information completely you will
        no longer be able to fully utilize the service we provide to you. You
        may email us at support@orphanboards.com to remove your contact
        information from our database provided we have completed all your
        pending transactions. Please note that information is never completely
        removed from our history and disaster recovery archives. In addition,
        once PII has been provided to a third party, we cannot force that third
        party to delete the PII they have been provided.{" "}
      </p>
      <h2>12. Changes to Privacy Policy </h2>
      <p>
        If our Privacy Policy or procedures change, we will immediately post
        those changes on our Mobile Application/Website. You can access our most
        recent Privacy Policy (link to Privacy Policy). Any such changes will be
        effective immediately upon being posted, unless otherwise stated in the
        change. Your continued use of the Mobile Application/Website after the
        posting will constitute your acceptance of, and agreement to be bound by
        those changes.
      </p>
      <h2>13. Effective Date </h2>
      <p>This Privacy Policy is effective as of June 24, 2023.</p>
    </div>
  );
}

export default PrivacyPolicy;

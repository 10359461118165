import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "../../../assets/images/avatar.png";
import { UPdateReport } from "../../services/reportservice";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { notify } from "../../utils/notify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Fileadd from "../../../assets/images/file_add.png";
import Imagedelete from "../../components/products/Imagedelete";
import ImagesUpload from "../../utils/ImagesUpload";
import VideoUpload from "../../utils/VideoUpload";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';



const condition = [
  {
    value: "excellent",
  },
  {
    value: "good",
  },
  {
    value: "bad",
  },
];
const sizes = [
  {
    value: "0-1ft",
  },
  {
    value: "1-2ft",
  },
  {
    value: "2-3ft",
  },
  {
    value: "3-4ft",
  },
  {
    value: "4-5ft",
  },
  {
    value: "5-6ft",
  },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ReportUpdate(props) {
  const { updstate } = props;
  const data = props.id;
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(true);
  const [title, settitle] = useState(data.locationTitle);
  const [profillle, setprofillle] = useState([]);
  const [profileUrl, setProfileUrl] = useState(data.images ? data.images : "");
  const [newUrl, setNewUrl] = useState([]);
  const [newFile, setNewFilet] = useState([]);
  const [productImage, setProductImage] = useState(data.images ? data.images : "");
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [videoUrl, setVideoUrl] = useState(data.videos ? data.videos : "");
  const [videoFile, setVideoFile] = useState(data.videos ? data.videos : "");
  const [ProfileImageurl, setProfileImageurl] = useState(null);
  const [ProfileImagefile, setProfileImagefile] = useState(null);
  const [updated, setUpdated] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false);


  const [productUrl, setProducturl] = useState(data.images);
  const [productImagelist, setProductImagelist] = useState(data.images);
  const [profileBackendURL, setProfileBackendURL] = useState(
    data.images ? data.images : ""
  );
  const [file_URL, setFile_Url] = useState([]);
  const [videoBackendURL, setVideoBackendURL] = useState(
    data.videos ? data.videos : ""
  );
  const [description, setdescription] = useState(data.description);
  const [UpdateVideoUrl, setUpdateVideoUrl] = useState(data?.videos);
  const [User, setUser] = useState(
    data.user.firstName ? data.user.firstName : ""
  );
  const [updata, setUpdata] = useState(false);
  const [loader, setLoader] = useState(false);
  const [idddd, setidddd] = useState(data._id);

  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));


  const handleSubmit = () => {
    // let formData = new FormData();
    // formData.append("locationTitle", title);
    // formData.append("description", description);
    // formData.append("images", JSON.stringify(profileBackendURL));
    // formData.append("videos", JSON.stringify(videoBackendURL));
    // if (profillle.length > 0) {
    //   formData.append("filess", profillle);
    // }
    // console.log("FORM DATA", formData);
    // debugger;
    // let obj = {
    //   locationTitle: title,
    //   description: description,
    //   images: JSON.stringify(profileBackendURL),
    //   videos: JSON.stringify(videoBackendURL),
    //   filess: profillle,
    // };

    // console.log(productUrl, "Producctt url");
    // console.log(productImagelist, "after add image");
    // console.log("productImage", productImage);

    // for (let i = 0; i < productImagelist.length; i++) {
    //   if (productImagelist[i].name) {
    //     newFile.push(productImagelist[i]);
    //   } else {
    //     newUrl.push(productImagelist[i]);
    //   }
    // }

    let newFiles = [];
    let videos = [];
    let images = [];
    videoFile.forEach((i, inx) => {
      if (typeof i == 'object') {
        newFiles.push(i);
      } else {
        videos.push(i);
      }
    })

    productImagelist.forEach((i, inx) => {
      if (typeof i == 'object') {
        newFiles.push(i);
      }
      else {
        images.push(i)
      }
    })

    let obj = {
      locationTitle: title,
      description: description,
      images: JSON.stringify(images),
      videos: JSON.stringify(videos)
    };
    if (newFiles.length) {
      obj["newFiles"] = newFiles;
    }

    setBtnLoader(true)
    UPdateReport(data?._id, obj)
      .then(({ data }) => {
        setBtnLoader(false)
        updstate("Update Successfully");
        handleClose();
        props.update(idddd, obj, productUrl);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.data.code == "400") {
          notify(err.response.data.message);
        }
        setLoader(false);
      });
  };

  const VideoChange = (e) => {
    let videoArr = [...videoFile];
    let videoURL = [...videoUrl];

    let file = e.target.files[0];
    if (file) {
      let url = URL.createObjectURL(file);
      videoArr.push(file);
      videoURL.push(url);
      setVideoUrl(videoURL);
      setVideoFile(videoArr);
    }
  }


  const ImageUpload = (e) => {
    let images = [...productImagelist];
    let imagesURL = [...productUrl];
    let file = e.target.files[0];
    setProfileImagefile(file);
    if (file) {
      let url = URL.createObjectURL(file);
      images.push(file);
      imagesURL.push(url)
      setProductImagelist(images);
      setProducturl(imagesURL);
    }
  }

  const deleteVideo = (inx, video) => {
    if (typeof video != 'object') {
      setUpdated(true);
    }

    let videoArr = [...videoFile];
    let videoURL = [...videoUrl];
    videoArr.splice(inx, 1);
    videoURL.splice(inx, 1);
    setVideoFile(videoArr);
    setVideoUrl(videoURL);
  }

  const deleteImg = (inx, img) => {
    if (typeof img != 'object') {
      setUpdated(true);
    }

    let imgArr = [...productImagelist];
    let imgURL = [...productUrl];
    imgArr.splice(inx, 1);
    imgURL.splice(inx, 1);
    setProducturl(imgURL);
    setProductImagelist(imgArr);
  }

  useEffect(() => {
    videoFile.forEach((i) => {
      if (typeof i == 'object') {
        setUpdated(true)
      }
    })

    productImagelist.forEach((i) => {
      if (typeof i == 'object') {
        setUpdated(true)
      }
    })

    if (description !== data?.description) {
      setUpdated(true);
    }
    else {
      setUpdated(false)
    }
  }, [videoFile, productImagelist, updated, description])



  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div className="maindivuser">
          <h2 style={{ paddingLeft: "10px" }}>Beach Report Data</h2>
          <hr style={{ width: "96%" }}></hr>
          <div className="useruPDATE">
            <div className="listingupdate">
              <TextField
                label="Beach"
                id="margin-none"
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                  if (data.locationTitle != e.target.value) {
                    setUpdata(true);
                  } else {
                    setUpdata(false);
                  }
                }}
                disabled
              />
            </div>
            <div className="listingupdate">
              <TextField
                label="Description"
                id="margin-none"
                value={description}
                onChange={(e) => {
                  setdescription(e.target.value);
                  if (data.description != e.target.value) {
                    setUpdata(true);
                  } else {
                    setUpdata(false);
                  }
                }}
              />
            </div>
          </div>
          <div>
            {/* <ImagesUpload
              title="Report"
              data={data}
              productUrl={productUrl}
              setProducturl={setProducturl}
              setProductImagelist={setProductImagelist}
              productImagelist={productImagelist}
              setUpdata={setUpdata}
              updata={updata}
              loader3={loader3}
              setLoader3={setLoader3}
              setLoader={setLoader}
              loader={loader}
            />
            <VideoUpload
              title="Report"
              UpdateVideoUrl={UpdateVideoUrl}
              setUpdateVideoUrl={setUpdateVideoUrl}
              data={data}
              videoUrl={videoUrl}
              setVideoUrl={setVideoUrl}
              setVideoFile={setVideoFile}
              videoFile={videoFile}
              setUpdata={setUpdata}
              updata={updata}
              loader4={loader4}
              setLoader4={setLoader4}
              setLoader5={setLoader5}
              loader5={loader5}
            /> */}
            <div style={{ padding: "11px" }}>
              <div>Report Images</div>
              <div className="imgContainer">
                {productUrl.map((img, inx) => {
                  return (
                    <>
                      <div style={{ position: "relative", marginRight: "4%" }}>
                        <img className="imgSelected" src={img} />
                        <ClearIcon className="deleteIcon" style={{ fill: "red" }} onClick={() => deleteImg(inx, img)} />
                      </div>
                    </>
                  )
                })
                }
                {productUrl.length < 4 ? <label htmlFor="ProfileImage" className='flex justify-center' style={{ cursor: "pointer" }}>
                  <div className='plusDiv'>
                    <AddIcon />
                  </div>
                </label> : ""}
              </div>

              <input
                type="file"
                id="ProfileImage"
                name="ProfileImage"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => {
                  ImageUpload(e)
                }}
              />
            </div>
            <div style={{ padding: "11px" }}>
              <div>Report Videos</div>
              <div className="videoContainer">
                {videoUrl.map((video, inx) => {
                  return (
                    <>
                      <div className="videoPlayer" style={{ position: "relative" }} >
                        <video width="150"
                          autoPlay
                          muted
                        >
                          <source src={video} type="video/mp4" />
                          <source src={video} type="video/ogg" />
                          Your browser does not support HTML video.
                        </video>
                        <ClearIcon className="deleteIcon" style={{ fill: "red" }} onClick={() => deleteVideo(inx, video)} />
                      </div>
                    </>
                  )
                })}
                {videoUrl.length < 1 ? <label htmlFor="profileVideo" className='flex justify-center' style={{ cursor: "pointer" }}>
                  <div className='plusDiv' style={{ height: "86px", width: "150px" }}>
                    <AddIcon />
                  </div>
                </label> : ""}
              </div>
              <input
                type="file"
                id="profileVideo"
                name="profileVideo"
                style={{ display: 'none' }}
                accept="video/*"
                onChange={(e) => {
                  VideoChange(e)
                }}
              />
            </div>
          </div>
          <div style={{ padding: "11px" }}>
            <button className="addAdminBtn" style={{ border: "none", color: "white", cursor: "pointer" }} onClick={() => handleSubmit()}>
              {btnLoader ?
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress className="spinnerLoader" color="inherit" />
                </Box>
                : "Update"}
            </button>
          </div>
        </div>
      </Dialog >
    </div >
  );
}
export default ReportUpdate;

import { HTTP_CLIENT } from "../utils/interceptor";
import {
  Endpoint_userApi_Block,
  Endpoint_AdminApi_unBlock,
  Endpoint_AdminApi_Block,
  Endpoint_userApi_Conversation_Block,
} from "../utils/endpoint";
const Userapi_blockData = (obj) => {
  console.log("G", obj);
  // if (id){
  return HTTP_CLIENT.put(Endpoint_userApi_Block.userApi_Block + obj, obj);
  // }
};

const Userapi_bloc_ConversatiobkData = (obj) => {
  // if (id){
  return HTTP_CLIENT.put(
    Endpoint_userApi_Conversation_Block.userApi_Block,
    obj
  );
  // }
};

const Adminapi_blockData = (id) => {
  if (id) {
    return HTTP_CLIENT.put(Endpoint_AdminApi_Block.AdminApi_Block + id);
  }
};
const Adminapi_unblockData = (id) => {
  if (id) {
    return HTTP_CLIENT.put(Endpoint_AdminApi_unBlock.AdminApi_unBlock + id);
  }
};

export {
  Userapi_blockData,
  Adminapi_blockData,
  Adminapi_unblockData,
  Userapi_bloc_ConversatiobkData,
};

import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Avatar from "../../../assets/images/dummy.png";
import ImagePlaceholder from "../../../assets/images/imagePlacehoder.svg";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import ProgressiveImage from "react-progressive-graceful-image";
import { Select } from "@material-ui/core";
// import placeholderSrc from "./images/tiny.jpg";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useSelector } from "react-redux";
import { addReviewBeach, getReportReview } from "../../services/ReportApi";
import { CircularProgress } from "@mui/material";
import { notify } from "../../utils/notify";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ReportView(props) {
  const user = useSelector((state) => state.user.user);

  const data = props.resp;
  const [showImages, setShowImages] = useState(props ? data?.images : []);
  const [showVideos, setShowVideos] = useState(props ? data?.videos : []);
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(true);
  const [reviewedBy, setReviewedBy] = useState(null);
  const [reviewLoader, setReviewLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [marked, setMarked] = useState(
    props?.resp?.isReviewed ? props?.resp?.isReviewed : false
  );
  const [selected, setSelected] = useState({
    link: "",
    type: "",
  });

  const [filetype, setType] = useState(
    data?.images[0] ? "pic" : data?.videos[0] ? "vid" : ""
  );
  const [proimage, setProimage] = useState(
    filetype == "pic" ? data?.images[0] : data?.videos[0]
  );
  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const main_profile = (index, image) => {
    setProimage(image);
  };

  const Selecting = (media, type) => {
    let obj = {
      link: media,
      type: type,
    };
    setSelected(obj);
  };

  useEffect(() => {
    if (data.images.length) {
      setSelected({
        type: "image",
        link: data.images[0],
      });
    } else if (data.videos.length) {
      setSelected({
        type: "video",
        link: data.videos[0],
      });
    } else {
      setSelected({
        type: "",
        link: "",
      });
    }
  }, []);

  useEffect(() => {
    if (open) {
      if (user?.admin?.role == "superAdmin") {
        setReviewLoader(true);
        getBeachReportReview();
      }

      if (user?.admin?.role == "admin") {
        // addBeachReportReview();
      }
    }
  }, [open]);

  const getBeachReportReview = () => {
    getReportReview(props?.resp?._id)
      .then(({ data: { data } }) => {
        setReviewedBy(data);
        setReviewLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setReviewLoader(false);
      });
  };

  const addBeachReportReview = () => {
    let obj = {
      reportId: props?.resp?._id,
      adminId: user?.admin?.id,
      timeReviewed: moment(new Date()).format(),
    };

    setLoader(true);

    addReviewBeach(obj)
      .then(({ data }) => {
        setLoader(false);
        notify(data?.msg, "success");
        setMarked(true);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleMarkReview = () => {
    addBeachReportReview();
  };

  return (
    <div>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div className="lelel" style={{ paddingBottom: "20px" }}>
          <h2>Beach Report</h2>
          <div className="reportmainview">
            {/* <div className="reportimg">
              <div className="profile">
                {filetype == "pic" ? (
                  <ProgressiveImage src={proimage} placeholder={Loaadings}>
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading" : " loaded"}`}
                        src={src}
                        alt={ImagePlaceholder}
                        width={"100%"}
                        style={{ borderRadius: "2px" }}
                      />
                    )}
                  </ProgressiveImage>
                ) : filetype === "vid" ? (
                  <video
                    className="w-100 "
                    controls
                    style={{ borderRadius: "2px" }}
                  >
                    <source src={proimage} type="video/mp4"></source>
                  </video>
                ) : (
                  <img src={ImagePlaceholder} className="w-100" />
                )}
              </div>
              <div className="flex_images d-flex row ml-0 mr-0">
                {data?.images.map((pc, i) => {
                  return (
                    <div className="video-container col-3 pl-0 pr-0">
                      <ProgressiveImage src={pc} placeholder={Loaadings}>
                        {(src, loading) => (
                          <img
                            className={`image${loading ? " loading" : " loaded"
                              } imagesCrousal`}
                            src={src}
                            alt={ImagePlaceholder}
                            style={{ borderRadius: "2px" }}
                            onClick={() => {
                              main_profile(i, pc);
                              setType("pic");
                            }}
                          />
                        )}
                      </ProgressiveImage>
                    </div>
                  );
                })}
                {data?.videos.map((pc, i) => {
                  return (
                    <div className="video-container  col-3 pl-0 pr-0">
                      <video
                        src={pc ? pc : ImagePlaceholder}
                        onClick={() => {
                          main_profile(i, pc);
                          setType("vid");
                        }}
                        style={{
                          borderRadius: "2px", height: '60px',
                          objectFit: 'contain'
                        }}
                      ></video>
                    </div>
                  );
                })}
              </div>
            </div> */}

            <div>
              <div className="selectionView">
                {selected && selected?.link != "" ? (
                  selected?.type == "image" ? (
                    <img className="imgSelectedView" src={selected?.link} />
                  ) : selected?.type == "video" ? (
                    <video width="400" controls key={selected?.link} autoPlay>
                      <source src={selected?.link} type="video/mp4" />
                      <source src={selected?.link} type="video/ogg" />
                      Your browser does not support HTML video.
                    </video>
                  ) : (
                    ""
                  )
                ) : (
                  <img src={ImagePlaceholder} />
                )}
              </div>
              <div className="reportMediaView">
                {showImages.map((img) => {
                  return (
                    <>
                      <img
                        src={img}
                        className="imgCrouse"
                        style={{
                          cursor: "pointer",
                          height: "60px",
                          width: "60px",
                        }}
                        onClick={() => Selecting(img, "image")}
                      />
                    </>
                  );
                })}
                <div style={{ display: "flex" }}>
                  {showVideos.map((video, inx) => {
                    return (
                      <div style={{ position: "relative", marginRight: "12%" }}>
                        <video
                          width="150"
                          autoPlay
                          muted
                          className="imgCrouse"
                          style={{
                            marginRight: "8%",
                            cursor: "pointer",
                            height: "60px",
                            width: "60px",
                          }}
                          onClick={() => Selecting(video, "video")}
                        >
                          <source src={video} type="video/mp4" />
                          <source src={video} type="video/ogg" />
                          Your browser does not support HTML video.
                        </video>
                        <PlayCircleOutlineIcon
                          className="playIcon"
                          onClick={() => Selecting(video, "video")}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="reportcontent">
              {/* <div className='reportview'><h6>Title</h6><p>{data.title}</p></div> */}
              {/* <hr/> */}
              <div className="reportview">
                <h6 className="popUpHeading">Description</h6>
                <p className="popUpDetail">{data?.description}</p>
              </div>
              <hr />
              <div className="reportview">
                <h6 className="popUpHeading">Beach</h6>
                <p className="popUpDetail">{data?.locationTitle}</p>
              </div>
              <hr />
              <div className="reportview">
                <h6 className="popUpHeading">Email</h6>
                {data?.user?.email ? (
                  <p className="popUpDetail">{data?.user && data.user.email}</p>
                ) : (
                  <>
                    {data?.user &&
                      data?.user.map((item, i) => {
                        return (
                          <>
                            <p className="popUpDetail"> {item.email}</p>
                          </>
                        );
                      })}
                  </>
                )}
              </div>
              <hr />
              <div className="reportview">
                <h6 className="popUpHeading">Time</h6>
                <p className="popUpDetail">
                  {moment(data?.createdAt).fromNow()}
                </p>
              </div>
              <hr />
              {!reviewLoader &&
              user?.admin?.role == "superAdmin" &&
              reviewedBy ? (
                <div className="reportview">
                  <h6 className="popUpHeading">Review by: </h6>
                  <p className="popUpDetail">
                    {reviewedBy?.adminId?.firstName +
                      " " +
                      reviewedBy?.adminId?.lastName +
                      " at " +
                      moment(reviewedBy?.timeReviewed).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                  </p>
                </div>
              ) : null}

              {
                // user?.admin?.role === "admin" &&
                !marked ? (
                  <button
                    className="reviewButton"
                    onClick={() => handleMarkReview()}
                  >
                    {loader ? (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress
                          className="spinnerLoader"
                          color="inherit"
                        />
                      </Box>
                    ) : (
                      "Mark as review"
                    )}
                  </button>
                ) : (
                  <button className="reviewButton">
                    Already marked as review
                  </button>
                )
              }
            </div>
          </div>

          {/* <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        
          <Grid item xs={2} sm={4} md={4}>
              <label>Title</label>
            <Item>{data.title}</Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
              <label>Location Title</label>
            <Item>{data.locationTitle}</Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
              <label>Wave Size</label>
            <Item>{data.waveSize}</Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
              <label>Wave Form</label>
            <Item>{data.waveForm}</Item>
          </Grid>
       
      </Grid>
    </Box> */}
        </div>
      </Dialog>
    </div>
  );
}
export default ReportView;

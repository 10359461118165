import moment from "moment";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import ImgPlaceholder from "../../../assets/images/ChatPlaceholder1.svg";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ImgPlaceholder2 from "../../../assets/images/ChatPlaceholder2.svg";

export const DateHandler = ({ currentMsg, previousMsg, inx }) => {
  return (
    <>
      {inx == 0 ? (
        <div className="lineDiv">
          <div className="line"></div>
          <div className="lineText">
            {moment(currentMsg.createdAt).format("Do MMM, YYYY")}
          </div>
          <div className="line"></div>
        </div>
      ) : moment(currentMsg.createdAt).format("Do MMM, YYYY") !==
        moment(previousMsg.createdAt).format("Do MMM, YYYY") ? (
        <div className="lineDiv">
          <div className="line"></div>
          <div className="lineText">
            {moment(currentMsg.createdAt).format("Do MMM, YYYY")}
          </div>
          <div className="line"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const DisplayMsg = ({ item, onPress }) => {
  if (item?.position == "right") {
    return (
      <div className="leftChat rightt">
        <div className="mainfl">
          <div className="leftNamee">
            <h2>{item?.senderId?.firstName}</h2>
            <p className="">{moment(item?.createdAt).format("h:mm A")}</p>
          </div>
          <div className="editflex">
            <ModeEditOutlineIcon
              onClick={() => {
                onPress && onPress(item);
              }}
              style={{ cursor: "pointer", height: "15px" }}
            />
            <div
              className="chatcontent rightSideChat"
              onClick={() => {
                onPress && onPress(item);
              }}
              style={{ cursor: "pointer" }}
            >
              {item?.image ? (
                <>
                  <ProgressiveImage src={item?.image} placeholder={Loaadings}>
                    {(src, loading) => (
                      <img
                        className={`image${loading ? " loading" : " loaded"}`}
                        src={src}
                        alt={Avatar}
                      />
                    )}
                  </ProgressiveImage>
                </>
              ) : (
                <>
                  <p>{item?.text}</p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="chatimg rightImg">
          {item?.senderId?.profilePic ? (
            <ProgressiveImage
              src={item?.senderId?.profilePic}
              placeholder={Loaadings}
            >
              {(src, loading) => (
                <img
                  className={`image${loading ? " loading" : " loaded"}`}
                  src={src}
                  alt={Avatar}
                />
              )}
            </ProgressiveImage>
          ) : (
            <img className="image loaded" src={ImgPlaceholder2} />
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={"leftChat"}>
      <div className="chatimg">
        {item?.senderId?.profilePic ? (
          <div style={{ position: "relative" }}>
            <ProgressiveImage
              src={item?.senderId?.profilePic}
              placeholder={Loaadings}
            >
              {(src, loading) => (
                <img
                  className={`image${loading ? " loading" : " loaded"}`}
                  src={src}
                  alt={Avatar}
                />
              )}
            </ProgressiveImage>
          </div>
        ) : (
          <img className="image loaded" src={ImgPlaceholder} />
        )}
      </div>
      <div className="mainfl">
        <div className="leftNamee">
          <h2>{item?.senderId?.firstName}</h2>
          <p className="">{moment(item?.createdAt).format("h:mm A")}</p>
        </div>
        <div className="editflex">
          <div
            className="chatcontent"
            onClick={() => {
              onPress && onPress(item);
            }}
            style={{ cursor: "pointer" }}
          >
            {item?.image ? (
              <>
                <ProgressiveImage src={item?.image} placeholder={Loaadings}>
                  {(src, loading) => (
                    <img
                      className={`image${loading ? " loading" : " loaded"}`}
                      src={src}
                      alt={Avatar}
                    />
                  )}
                </ProgressiveImage>
              </>
            ) : (
              <>
                <p>{item?.text}</p>
              </>
            )}
          </div>
          <ModeEditOutlineIcon
            onClick={() => {
              onPress && onPress(item);
            }}
            style={{ cursor: "pointer", height: "15px" }}
          />
        </div>
      </div>
    </div>
  );
};

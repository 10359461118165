import { HTTP_CLIENT } from "../utils/interceptor";
import {
  Endpoint_Admin_Download,
  Endpoint_Productapi,
  Endpoint_add_product,
  ReviewAdminProduct,
} from "../utils/endpoint";
const ProductapiData = (query) => {
  if (query) {
    return HTTP_CLIENT.get(Endpoint_Productapi.productApi + query);
  } else {
    return HTTP_CLIENT.get(Endpoint_Productapi.productApi);
  }
};

const AddProductAPI = (obj) => {
  let formBody = new FormData();
  Object.keys(obj).forEach((key) => {
    formBody.append(key, obj[key]);
  });

  return HTTP_CLIENT.post(Endpoint_add_product.addProduct, formBody);
};

const ReviewAdmin = (obj) => {
  return HTTP_CLIENT.post(ReviewAdminProduct?.reviewAdmin, obj);
};

//getReviewProduct

const ReviewProduct = (id) => {
  return HTTP_CLIENT.get(ReviewAdminProduct?.getReviewProduct + id);
};

const GetAllProducts = () => {
  return HTTP_CLIENT.get(Endpoint_Admin_Download.products);
};

export {
  ProductapiData,
  AddProductAPI,
  ReviewAdmin,
  ReviewProduct,
  GetAllProducts,
};

import React, { useState, useEffect } from 'react';
import LoginPic from "../../assets/images/login.jpg";
import Switch from '@mui/material/Switch';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from '../../shared/utils/notify';
import { LoginApi } from '../../shared/services/Login_api';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from "../../shared/redux/reducers/userSlie";
import NewLogo from "../../assets/images/FullLogo.png";
import { setUserInfo, resetUserInfo } from "../../shared/redux/reducers/rememberMe"
import LogoFroth from "../../assets/images/logoFroth.png"


function Auth() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.rememberMe?.userInfo);
  const [email, setEmail] = useState(userDetails ? userDetails?.email : "");
  const [password, setPassword] = useState(userDetails ? userDetails?.password : "");
  const [showPassword, setShowPassword] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [switchValue, setSwitchValue] = useState(userDetails ? true : false);



  const handleLogin = () => {
    if (email.length == 0 || password.length == 0) {
      notify("Please enter all field")
    }
    else {
      let obj = {
        email: email,
        password: password
      }
      setBtnLoader(true)
      LoginApi(obj)
        .then(({ data }) => {
          if (data) {
            if (data.status == "404") {
              notify("Not Valid Email & Password !!");
              setBtnLoader(false)
            } else {
              setBtnLoader(false)
              dispatch(setUser(data));
              if (switchValue) {
                dispatch(setUserInfo(obj));
              }
            }
          }
        })
        .catch((e) => {
          setBtnLoader(false);
          notify(e?.response?.data?.message);
        });
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  }

  const handleSwitch = () => {

    if (switchValue) {
      dispatch(resetUserInfo());
      setEmail("");
      setPassword("");
    }

    setSwitchValue(!switchValue)
  }



  return (
    <div style={{
      backgroundImage: `url(${LoginPic})`
    }}
      className="mainBackground">
      <div className='cardLogin'>
        <img
          src={LogoFroth}
          className='loginLogo' />
        <input
          className={email.length ? 'inputWithValue inputLogin' : 'inputLogin'}
          placeholder='Email'
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown} />
        <div style={{ position: "relative" }}>
          <input
            className={password.length ? 'inputWithValue inputLogin' : 'inputLogin'}
            placeholder='Password' type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            onKeyDown={handleKeyDown} />
          {showPassword ?
            <VisibilityIcon
              className="ShowPasswordIcon"
              onClick={() => setShowPassword(!showPassword)}
              style={password.length ? { color: "#3282b8" } : {}}
            />
            :
            <VisibilityOffIcon
              className='ShowPasswordIcon'
              onClick={() => setShowPassword(!showPassword)}
              style={password.length ? { color: "#3282b8" } : {}}
            />
          }
        </div>

        <div className='rememberMe'>
          <p>Remember me?</p>
          <Switch size="small" onClick={() => handleSwitch()} checked={switchValue} />
        </div>

        <button className='loginBtn' onClick={() => handleLogin()}> {
          btnLoader ?
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress className="spinnerLoader" color="inherit" />
            </Box> : "Login"
        }</button>

      </div >
    </div >
  )
}

export default Auth
import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@material-ui/core/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Loadering from "../../../assets/images/loading.gif";
import { review_by_product,review_by_report } from "../../services/Veiwers";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ViewedPopUp(props) {
  const data = props.resp;
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(true);
  const [opens, setOpens] = useState(true);

  let sortedProducts = [];

  const handleClose = () => {
    setOpen(false);
    props.close(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  useEffect(() => {
    setOpens(true);
    let query = data?._id;
    if(props.title == "report"){
      review_by_report(query).then(({ data }) => {
        if (data) {
          setdataUser(data?.data);
          setOpens(false);
        }
      });
    }
    else{
      review_by_product(query).then(({ data }) => {
        if (data) {
          setdataUser(data?.data);
          setOpens(false);
        }
      });
    }
   
  }, []);
  return (
    <div>
      <Dialog
        className="detailss"
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        {opens ? (
          <div className="centerr">
            <img src={Loadering} className="loaderr" />
          </div>
        ) : (
          <div className="lelel ">
            <h2>Re-Viewed by</h2>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SR</TableCell>
                    <TableCell align="center">Admin</TableCell>
                    <TableCell align="center">{props.title == "report" ?"Report": "Product"}</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataUser?.map((dats, i) => {
                    return (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">{i + 1} </TableCell>
                          <TableCell align="center">
                            {dats?.adminId?.firstName}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {props.title == "report" ? dats?.reportId?.locationTitle : dats?.productId?.title}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {dats?.dateReviewed}{" "}
                          </TableCell>
                          <TableCell align="center">
                            {dats?.timeReviewed}{" "}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Dialog>
    </div>
  );
}
export default ViewedPopUp;
